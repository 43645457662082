@charset "UTF-8";
/**
	Позволяет сделать текст переносимым на след строку и добавляет многоточие,
	если текст вылезает за пределы блока
 */
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --kite-gutter-x: 32px;
  --kite-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--kite-gutter-x) * 0.5);
  padding-left: calc(var(--kite-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --kite-breakpoint-xs: 0;
  --kite-breakpoint-sm: 576px;
  --kite-breakpoint-md: 768px;
  --kite-breakpoint-lg: 992px;
  --kite-breakpoint-xl: 1200px;
  --kite-breakpoint-xxl: 1440px;
}

.row {
  --kite-gutter-x: 32px;
  --kite-gutter-y: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--kite-gutter-y));
  margin-right: calc(-0.5 * var(--kite-gutter-x));
  margin-left: calc(-0.5 * var(--kite-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--kite-gutter-x) * 0.5);
  padding-left: calc(var(--kite-gutter-x) * 0.5);
  margin-top: var(--kite-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --kite-gutter-x: 0px;
}

.g-0,
.gy-0 {
  --kite-gutter-y: 0px;
}

.g-100,
.gx-100 {
  --kite-gutter-x: 2px;
}

.g-100,
.gy-100 {
  --kite-gutter-y: 2px;
}

.g-200,
.gx-200 {
  --kite-gutter-x: 4px;
}

.g-200,
.gy-200 {
  --kite-gutter-y: 4px;
}

.g-250,
.gx-250 {
  --kite-gutter-x: 6px;
}

.g-250,
.gy-250 {
  --kite-gutter-y: 6px;
}

.g-300,
.gx-300 {
  --kite-gutter-x: 8px;
}

.g-300,
.gy-300 {
  --kite-gutter-y: 8px;
}

.g-350,
.gx-350 {
  --kite-gutter-x: 10px;
}

.g-350,
.gy-350 {
  --kite-gutter-y: 10px;
}

.g-400,
.gx-400 {
  --kite-gutter-x: 12px;
}

.g-400,
.gy-400 {
  --kite-gutter-y: 12px;
}

.g-500,
.gx-500 {
  --kite-gutter-x: 16px;
}

.g-500,
.gy-500 {
  --kite-gutter-y: 16px;
}

.g-600,
.gx-600 {
  --kite-gutter-x: 24px;
}

.g-600,
.gy-600 {
  --kite-gutter-y: 24px;
}

.g-700,
.gx-700 {
  --kite-gutter-x: 32px;
}

.g-700,
.gy-700 {
  --kite-gutter-y: 32px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --kite-gutter-x: 0px;
  }
  .g-sm-0,
  .gy-sm-0 {
    --kite-gutter-y: 0px;
  }
  .g-sm-100,
  .gx-sm-100 {
    --kite-gutter-x: 2px;
  }
  .g-sm-100,
  .gy-sm-100 {
    --kite-gutter-y: 2px;
  }
  .g-sm-200,
  .gx-sm-200 {
    --kite-gutter-x: 4px;
  }
  .g-sm-200,
  .gy-sm-200 {
    --kite-gutter-y: 4px;
  }
  .g-sm-250,
  .gx-sm-250 {
    --kite-gutter-x: 6px;
  }
  .g-sm-250,
  .gy-sm-250 {
    --kite-gutter-y: 6px;
  }
  .g-sm-300,
  .gx-sm-300 {
    --kite-gutter-x: 8px;
  }
  .g-sm-300,
  .gy-sm-300 {
    --kite-gutter-y: 8px;
  }
  .g-sm-350,
  .gx-sm-350 {
    --kite-gutter-x: 10px;
  }
  .g-sm-350,
  .gy-sm-350 {
    --kite-gutter-y: 10px;
  }
  .g-sm-400,
  .gx-sm-400 {
    --kite-gutter-x: 12px;
  }
  .g-sm-400,
  .gy-sm-400 {
    --kite-gutter-y: 12px;
  }
  .g-sm-500,
  .gx-sm-500 {
    --kite-gutter-x: 16px;
  }
  .g-sm-500,
  .gy-sm-500 {
    --kite-gutter-y: 16px;
  }
  .g-sm-600,
  .gx-sm-600 {
    --kite-gutter-x: 24px;
  }
  .g-sm-600,
  .gy-sm-600 {
    --kite-gutter-y: 24px;
  }
  .g-sm-700,
  .gx-sm-700 {
    --kite-gutter-x: 32px;
  }
  .g-sm-700,
  .gy-sm-700 {
    --kite-gutter-y: 32px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --kite-gutter-x: 0px;
  }
  .g-md-0,
  .gy-md-0 {
    --kite-gutter-y: 0px;
  }
  .g-md-100,
  .gx-md-100 {
    --kite-gutter-x: 2px;
  }
  .g-md-100,
  .gy-md-100 {
    --kite-gutter-y: 2px;
  }
  .g-md-200,
  .gx-md-200 {
    --kite-gutter-x: 4px;
  }
  .g-md-200,
  .gy-md-200 {
    --kite-gutter-y: 4px;
  }
  .g-md-250,
  .gx-md-250 {
    --kite-gutter-x: 6px;
  }
  .g-md-250,
  .gy-md-250 {
    --kite-gutter-y: 6px;
  }
  .g-md-300,
  .gx-md-300 {
    --kite-gutter-x: 8px;
  }
  .g-md-300,
  .gy-md-300 {
    --kite-gutter-y: 8px;
  }
  .g-md-350,
  .gx-md-350 {
    --kite-gutter-x: 10px;
  }
  .g-md-350,
  .gy-md-350 {
    --kite-gutter-y: 10px;
  }
  .g-md-400,
  .gx-md-400 {
    --kite-gutter-x: 12px;
  }
  .g-md-400,
  .gy-md-400 {
    --kite-gutter-y: 12px;
  }
  .g-md-500,
  .gx-md-500 {
    --kite-gutter-x: 16px;
  }
  .g-md-500,
  .gy-md-500 {
    --kite-gutter-y: 16px;
  }
  .g-md-600,
  .gx-md-600 {
    --kite-gutter-x: 24px;
  }
  .g-md-600,
  .gy-md-600 {
    --kite-gutter-y: 24px;
  }
  .g-md-700,
  .gx-md-700 {
    --kite-gutter-x: 32px;
  }
  .g-md-700,
  .gy-md-700 {
    --kite-gutter-y: 32px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --kite-gutter-x: 0px;
  }
  .g-lg-0,
  .gy-lg-0 {
    --kite-gutter-y: 0px;
  }
  .g-lg-100,
  .gx-lg-100 {
    --kite-gutter-x: 2px;
  }
  .g-lg-100,
  .gy-lg-100 {
    --kite-gutter-y: 2px;
  }
  .g-lg-200,
  .gx-lg-200 {
    --kite-gutter-x: 4px;
  }
  .g-lg-200,
  .gy-lg-200 {
    --kite-gutter-y: 4px;
  }
  .g-lg-250,
  .gx-lg-250 {
    --kite-gutter-x: 6px;
  }
  .g-lg-250,
  .gy-lg-250 {
    --kite-gutter-y: 6px;
  }
  .g-lg-300,
  .gx-lg-300 {
    --kite-gutter-x: 8px;
  }
  .g-lg-300,
  .gy-lg-300 {
    --kite-gutter-y: 8px;
  }
  .g-lg-350,
  .gx-lg-350 {
    --kite-gutter-x: 10px;
  }
  .g-lg-350,
  .gy-lg-350 {
    --kite-gutter-y: 10px;
  }
  .g-lg-400,
  .gx-lg-400 {
    --kite-gutter-x: 12px;
  }
  .g-lg-400,
  .gy-lg-400 {
    --kite-gutter-y: 12px;
  }
  .g-lg-500,
  .gx-lg-500 {
    --kite-gutter-x: 16px;
  }
  .g-lg-500,
  .gy-lg-500 {
    --kite-gutter-y: 16px;
  }
  .g-lg-600,
  .gx-lg-600 {
    --kite-gutter-x: 24px;
  }
  .g-lg-600,
  .gy-lg-600 {
    --kite-gutter-y: 24px;
  }
  .g-lg-700,
  .gx-lg-700 {
    --kite-gutter-x: 32px;
  }
  .g-lg-700,
  .gy-lg-700 {
    --kite-gutter-y: 32px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --kite-gutter-x: 0px;
  }
  .g-xl-0,
  .gy-xl-0 {
    --kite-gutter-y: 0px;
  }
  .g-xl-100,
  .gx-xl-100 {
    --kite-gutter-x: 2px;
  }
  .g-xl-100,
  .gy-xl-100 {
    --kite-gutter-y: 2px;
  }
  .g-xl-200,
  .gx-xl-200 {
    --kite-gutter-x: 4px;
  }
  .g-xl-200,
  .gy-xl-200 {
    --kite-gutter-y: 4px;
  }
  .g-xl-250,
  .gx-xl-250 {
    --kite-gutter-x: 6px;
  }
  .g-xl-250,
  .gy-xl-250 {
    --kite-gutter-y: 6px;
  }
  .g-xl-300,
  .gx-xl-300 {
    --kite-gutter-x: 8px;
  }
  .g-xl-300,
  .gy-xl-300 {
    --kite-gutter-y: 8px;
  }
  .g-xl-350,
  .gx-xl-350 {
    --kite-gutter-x: 10px;
  }
  .g-xl-350,
  .gy-xl-350 {
    --kite-gutter-y: 10px;
  }
  .g-xl-400,
  .gx-xl-400 {
    --kite-gutter-x: 12px;
  }
  .g-xl-400,
  .gy-xl-400 {
    --kite-gutter-y: 12px;
  }
  .g-xl-500,
  .gx-xl-500 {
    --kite-gutter-x: 16px;
  }
  .g-xl-500,
  .gy-xl-500 {
    --kite-gutter-y: 16px;
  }
  .g-xl-600,
  .gx-xl-600 {
    --kite-gutter-x: 24px;
  }
  .g-xl-600,
  .gy-xl-600 {
    --kite-gutter-y: 24px;
  }
  .g-xl-700,
  .gx-xl-700 {
    --kite-gutter-x: 32px;
  }
  .g-xl-700,
  .gy-xl-700 {
    --kite-gutter-y: 32px;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --kite-gutter-x: 0px;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --kite-gutter-y: 0px;
  }
  .g-xxl-100,
  .gx-xxl-100 {
    --kite-gutter-x: 2px;
  }
  .g-xxl-100,
  .gy-xxl-100 {
    --kite-gutter-y: 2px;
  }
  .g-xxl-200,
  .gx-xxl-200 {
    --kite-gutter-x: 4px;
  }
  .g-xxl-200,
  .gy-xxl-200 {
    --kite-gutter-y: 4px;
  }
  .g-xxl-250,
  .gx-xxl-250 {
    --kite-gutter-x: 6px;
  }
  .g-xxl-250,
  .gy-xxl-250 {
    --kite-gutter-y: 6px;
  }
  .g-xxl-300,
  .gx-xxl-300 {
    --kite-gutter-x: 8px;
  }
  .g-xxl-300,
  .gy-xxl-300 {
    --kite-gutter-y: 8px;
  }
  .g-xxl-350,
  .gx-xxl-350 {
    --kite-gutter-x: 10px;
  }
  .g-xxl-350,
  .gy-xxl-350 {
    --kite-gutter-y: 10px;
  }
  .g-xxl-400,
  .gx-xxl-400 {
    --kite-gutter-x: 12px;
  }
  .g-xxl-400,
  .gy-xxl-400 {
    --kite-gutter-y: 12px;
  }
  .g-xxl-500,
  .gx-xxl-500 {
    --kite-gutter-x: 16px;
  }
  .g-xxl-500,
  .gy-xxl-500 {
    --kite-gutter-y: 16px;
  }
  .g-xxl-600,
  .gx-xxl-600 {
    --kite-gutter-x: 24px;
  }
  .g-xxl-600,
  .gy-xxl-600 {
    --kite-gutter-y: 24px;
  }
  .g-xxl-700,
  .gx-xxl-700 {
    --kite-gutter-x: 32px;
  }
  .g-xxl-700,
  .gy-xxl-700 {
    --kite-gutter-y: 32px;
  }
}