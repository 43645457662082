@use '~@tutu/mother-of-tokens/styles/tooltip.scss';
@use '~@tutu/mother-of-tokens/styles/panel.scss';

.tooltip {
	z-index: 10;
}

.panel {
	padding: var(--kite-tooltip-500-padding-vertical) var(--kite-tooltip-500-padding-horizontal);
	min-width: var(--kite-tooltip-500-min-width);
	min-height: var(--kite-tooltip-500-min-height);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tip {
	position: absolute;
	fill: var(--kite-panel-primary);
}

.tip-top {
	bottom: auto;
}

.tip-bottom {
	top: 0;
	transform: translateY(-100%) rotate(180deg);
}

.tip-top,
.tip-bottom {
	&.tip-start {
		left: var(--kite-tooltip-500-tip-padding-horizontal);
	}

	&.tip-end {
		right: var(--kite-tooltip-500-tip-padding-horizontal);
	}
}

.tip-left {
	right: 0;

	&.tip-start {
		transform: rotate(-90deg);
		transform-origin: top right;
		top: var(--kite-tooltip-500-tip-padding-vertical);
	}

	&.tip-middle {
		transform: translateX(50%) translateY(50%) rotate(-90deg);
		transform-origin: top center;
	}

	&.tip-end {
		bottom: var(--kite-tooltip-500-tip-padding-vertical);
		transform: translateX(100%) translateY(100%) rotate(-90deg);
		transform-origin: top left;
	}
}

.tip-right {
	left: 0;

	&.tip-start {
		transform: rotate(90deg);
		transform-origin: top left;
		top: var(--kite-tooltip-500-tip-padding-vertical);
	}

	&.tip-middle {
		transform: translateX(-50%) translateY(50%) rotate(90deg);
		transform-origin: top center;
	}

	&.tip-end {
		bottom: var(--kite-tooltip-500-tip-padding-vertical);
		transform: translateX(-100%) translateY(100%) rotate(90deg);
		transform-origin: top right;
	}
}
