@use '~@tutu/mother-of-tokens/styles/segmented-control';

$prefix: '--kite-segmented-control';
$layoutPrefix: '#{$prefix}-layout';

.segmentedControl {
	display: inline-flex;
	background: var(#{$prefix}-background);
	overflow: hidden;

	&.fullWidth {
		display: flex;
	}
}

$sizes: 400, 500, 600;

@each $size in $sizes {
	.layout-#{$size} {
		$layoutSize: #{$layoutPrefix}-#{$size};

		border-radius: var(#{$layoutSize}-border-radius);
		padding: var(#{$layoutSize}-padding-vertical) var(#{$layoutSize}-padding-horizontal);
	}
}
