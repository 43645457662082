.banner {
	position: relative;
	cursor: pointer;

	& .cross {
		position: absolute;
		top: 0;
		right: 0;
	}
}
