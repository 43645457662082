/**
* AUTOMATICALLY GENERATED FILE
*
* Do not change anything manually!
* Generated on Fri, 13 Dec 2024 11:32:09 GMT
*/
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_thin_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_thin_italic.woff) format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_thin.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_thin.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_regular.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_medium_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_medium_italic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_medium.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_light_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_light_italic.woff) format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_light.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_extra-light_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_extra-light_italic.woff) format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_extra-light.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_extra-light.woff) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_extra-bold_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_extra-bold_italic.woff) format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_extra-bold.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_extra-bold.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_demi-bold_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_demi-bold_italic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_demi-bold.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_demi-bold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_bold_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_bold_italic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_bold.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_black_italic.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_black_italic.woff) format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Tutu Sans";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/tutu-sans_black.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/tutu-sans_black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PT Serif";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/pt-serif_regular.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/pt-serif_regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PT Serif";
  src: url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff2/pt-serif_bold.woff2) format("woff2"), url(https://cdn1.tu-tu.ru/lib-assets/@tutu/mother-of-tokens@1.0.189/woff/pt-serif_bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/**
* AUTOMATICALLY GENERATED FILE
*
* Do not change anything manually!
* Generated on Fri, 13 Dec 2024 11:32:11 GMT
*/
:root,
[data-theme=light]:not([data-style=on-grey]),
[data-theme=light] [data-theme=inverted] [data-theme=inverted]:not([data-style=on-grey]),
[data-theme=dark] [data-theme=inverted]:not([data-style=on-grey]),
[data-theme=dark] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted]:not([data-style=on-grey]) {
  --kite-main-layout-color: rgb(255, 255, 255);
}

:root,
[data-theme=light][data-style=on-grey],
[data-theme=light] [data-theme=inverted] [data-theme=inverted][data-style=on-grey],
[data-theme=dark] [data-theme=inverted][data-style=on-grey],
[data-theme=dark] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted][data-style=on-grey] {
  --kite-main-layout-color: rgb(245, 245, 249);
}

[data-theme=dark]:not([data-style=on-grey]),
[data-theme=dark] [data-theme=inverted] [data-theme=inverted]:not([data-style=on-grey]),
[data-theme=light] [data-theme=inverted]:not([data-style=on-grey]),
[data-theme=light] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted]:not([data-style=on-grey]) {
  --kite-main-layout-color: rgb(38, 39, 56);
}

[data-theme=dark][data-style=on-grey],
[data-theme=dark] [data-theme=inverted] [data-theme=inverted][data-style=on-grey],
[data-theme=light] [data-theme=inverted][data-style=on-grey],
[data-theme=light] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted][data-style=on-grey] {
  --kite-main-layout-color: rgb(38, 39, 56);
}

:root {
  --kite-main-layout-margin-horizontal-xs: 16px;
  --kite-main-layout-margin-horizontal-sm: 16px;
  --kite-main-layout-margin-horizontal-md: 16px;
  --kite-main-layout-margin-horizontal-lg: 24px;
  --kite-main-layout-margin-horizontal-xl: 32px;
  --kite-main-layout-margin-horizontal-xxl: 32px;
  --kite-main-layout-gutter-xs: 16px;
  --kite-main-layout-gutter-sm: 16px;
  --kite-main-layout-gutter-md: 16px;
  --kite-main-layout-gutter-lg: 24px;
  --kite-main-layout-gutter-xl: 32px;
  --kite-main-layout-gutter-xxl: 32px;
}

/**
* AUTOMATICALLY GENERATED FILE
*
* Do not change anything manually!
* Generated on Fri, 13 Dec 2024 11:32:11 GMT
*/
:root,
[data-theme=light],
[data-theme=light] [data-theme=inverted] [data-theme=inverted],
[data-theme=dark] [data-theme=inverted],
[data-theme=dark] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted] {
  --kite-text-primary: rgb(38, 39, 56);
  --kite-text-secondary: rgba(38, 39, 56, 0.8);
  --kite-text-brand: rgb(107, 88, 252);
  --kite-text-positive: rgb(22, 165, 17);
  --kite-text-warning: rgb(219, 121, 9);
  --kite-text-negative: rgb(243, 77, 22);
}

[data-theme=dark],
[data-theme=dark] [data-theme=inverted] [data-theme=inverted],
[data-theme=light] [data-theme=inverted],
[data-theme=light] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted] {
  --kite-text-primary: rgb(242, 242, 247);
  --kite-text-secondary: rgba(242, 242, 247, 0.8);
  --kite-text-brand: rgb(147, 139, 253);
  --kite-text-positive: rgb(99, 207, 28);
  --kite-text-warning: rgb(253, 187, 33);
  --kite-text-negative: rgb(255, 126, 66);
}

ul,
ol {
  margin: 0;
  padding: 0;
}

html,
html {
  -webkit-text-size-adjust: 100%;
}

html[data-theme],
html[data-theme][data-style] {
  background-color: var(--kite-main-layout-color);
}

[data-ds=kite] body,
[data-ds=kite] body[data-ds=kite] {
  color: var(--kite-text-primary, black);
  background-color: var(--kite-main-layout-color);
  margin: 0;
}

details,
details,
main,
main {
  display: block;
}

h1,
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

hr,
hr {
  height: 0;
  overflow: visible;
  box-sizing: content-box;
}

abbr[title],
abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

button,
button,
input,
input,
optgroup,
optgroup,
select,
select,
textarea,
textarea {
  margin: 0;
}

button,
button,
input,
input {
  overflow: visible;
}

button,
button,
select,
select {
  text-transform: none;
}

[type=button],
[type=button],
[type=reset],
[type=reset],
[type=submit],
[type=submit],
button,
button {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type=button]:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring,
button:-moz-focusring {
  outline: 0;
}

fieldset,
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend,
legend {
  color: inherit;
  display: table;
  padding: 0;
  max-width: 100%;
  box-sizing: border-box;
  white-space: normal;
}

progress,
progress {
  vertical-align: baseline;
}

textarea,
textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search],
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

[type=search]::-webkit-search-decoration,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button,
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary,
summary {
  display: list-item;
}

template,
template {
  display: none;
}

a,
a,
button,
button,
input,
input,
a:focus-ring,
a:focus-ring,
button:focus-ring,
button:focus-ring,
input:focus-ring,
input:focus-ring {
  outline: 0;
}

:root, [data-theme=light], [data-theme=light][data-theme=inverted] [data-theme=inverted], [data-theme=dark] [data-theme=inverted], [data-theme=dark] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted] {
  --kite-scoped-theme: light;
}

[data-theme=dark], [data-theme=dark][data-theme=inverted] [data-theme=inverted], [data-theme=light] [data-theme=inverted], [data-theme=light] [data-theme=inverted] [data-theme=inverted] [data-theme=inverted] {
  --kite-scoped-theme: dark;
}