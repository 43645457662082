@use '~@tutu/mother-of-tokens/styles/modal.scss';

.scrim {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--kite-modal-scrim);
}
