@use '~@tutu/mother-of-tokens/styles/label-value.scss';
@use 'sass/helpers.scss' as utils;

.table {
	&-label {
		&-fullwidth {
			width: 100%;
		}

		&-autowidth {
			width: auto;
		}
	}

	&-value {
		align-self: flex-start;
		margin-left: auto;
	}

	&.with-max-lines {
		.table-value,
		.table-label {
			@include utils.truncate();
			flex: 0 0 auto;
			word-wrap: break-word;
		}
	}
}
