@import '~@tutu/mother-of-tokens/styles/logo.scss';

.logo {
	height: 1em;
	vertical-align: middle;
	padding-bottom: 0.25em;

	&-primary {
		&-left {
			fill: var(--kite-logo-default-primary-left);
		}
		&-right {
			fill: var(--kite-logo-default-primary-right);
		}

		&-hoverable:hover {
			& .logo-primary-left {
				fill: var(--kite-logo-hover-primary-left);
			}
			& .logo-primary-right {
				fill: var(--kite-logo-hover-primary-right);
			}
		}
	}
}
