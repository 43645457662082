@use '~@tutu/mother-of-tokens/styles/label-value.scss';
@use 'sass/helpers.scss' as utils;

$variants: (
	'header-x-large',
	'header-large',
	'header-medium',
	'header-small',
	'paragraph-large-compact',
	'paragraph-large',
	'paragraph-medium-compact',
	'paragraph-large',
	'paragraph-medium-compact',
	'paragraph-medium',
	'paragraph-small'
);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $variant in $variants {
			.control.#{$variant}#{$infix} {
				padding-top: var(--kite-label-value-#{$variant}-control-padding-top);
				padding-bottom: var(--kite-label-value-#{$variant}-control-padding-bottom);
				min-height: var(--kite-label-value-#{$variant}-control-min-height);
				gap: var(--kite-label-value-#{$variant}-control-gap);
			}
		}
	}
}

.control {
	justify-content: unset;
	width: auto;
}

.label {
	min-width: fit-content;
	flex: none;
}

.value {
	min-width: fit-content;
	flex: 1;
}

.text-clamp {
	@include utils.truncate();
	-webkit-line-clamp: var(--max-lines);
}
