@use 'sass/helpers.scss' as utils;
@use '../FormGroup/form-group.scss' as formGroup;

@use '~@tutu/mother-of-tokens/styles/input.scss';
@use '~@tutu/mother-of-tokens/styles/rich-icon.scss';
@use '~@tutu/mother-of-tokens/styles/public/typography.scss';

$size-list: (500, 600, 700);

@mixin input-placeholder-font-style($size) {
	@if $size == 500 {
		@include typography.compact-500-normal;
	}

	@if $size == 600 {
		@include typography.compact-600-normal;
	}

	@if $size == 700 {
		@include typography.big-300-normal;
	}
}

// Данная миксина заведена и используется для того, чтобы родительской обертке инпута
// устанавливать цвет, идентичный цвету автофилла инпута браузера.
// В будущем, возможно, избавимся от необходимости красить фон.
@mixin onInputAutofill() {
	&:has(:-webkit-autofill, :autofill) {
		//	Chromium
		@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
			background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4));
		}

		//	Firefox
		@-moz-document url-prefix() {
			background-color: rgb(255, 252, 200);
		}

		//	Safari
		@media not all and (min-resolution: 0.001dpcm) {
			background-color: rgba(250, 255, 189);
		}
	}
}

.root {
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	background-color: var(--kite-input-background-default);
	border-color: var(--kite-input-border-default);
	border-style: solid;

	@include onInputAutofill();

	&:not(.disabled):hover {
		cursor: pointer;
		border-color: var(--kite-input-border-hover);
	}

	&:focus-within {
		border-color: var(--kite-input-border-focused);
		border-style: solid;
	}

	&.disabled {
		border-color: var(--kite-input-border-disabled);
		background-color: var(--kite-input-background-disabled);
	}

	&.error {
		background-color: var(--kite-input-background-error);
	}

	&:has(:invalid) {
		background-color: var(--kite-input-background-error);
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $size in $size-list {
			.size#{$infix}-#{$size} {
				min-height: var(--kite-input-layout-#{$size}-min-size);
				max-height: var(--kite-input-layout-#{$size}-min-size);
				height: var(--kite-input-layout-#{$size}-min-size);
				padding-left: var(--kite-input-content-#{$size}-padding-horizontal);
				padding-right: var(--kite-input-content-#{$size}-padding-horizontal);
				border-radius: var(--kite-input-layout-#{$size}-border-radius);
				border-width: var(--kite-input-layout-#{$size}-border-width-default);

				.main {
					.input {
						padding-left: var(--kite-input-content-#{$size}-input-padding-horizontal);
						padding-right: var(--kite-input-content-#{$size}-input-padding-horizontal);
					}
				}

				&.with-label {
					.main {
						.input {
							padding-top: calc(
								(var(--kite-input-layout-#{$size}-min-size) / 2) - var(
										--kite-input-content-#{$size}-padding-horizontal
									)
							);
						}
					}
				}

				&.with-addon-left {
					.addon-left {
						padding-right: var(--kite-input-content-#{$size}-addon-left-padding-right);
					}
				}

				&.with-addon-right {
					.addon-right {
						padding-left: var(--kite-input-content-#{$size}-addon-right-padding-left);
					}
				}

				&:hover {
					border-width: var(--kite-input-layout-#{$size}-border-width-hover);
				}

				&:focus-within {
					border-width: var(--kite-input-layout-#{$size}-border-width-focused);
					padding-left: calc(
						var(--kite-input-content-#{$size}-padding-horizontal) -
							(
								var(--kite-input-layout-#{$size}-border-width-focused) - var(
										--kite-input-layout-#{$size}-border-width-default
									)
							)
					);
					padding-right: calc(
						var(--kite-input-content-#{$size}-padding-horizontal) -
							(
								var(--kite-input-layout-#{$size}-border-width-focused) - var(
										--kite-input-layout-#{$size}-border-width-default
									)
							)
					);

					.label {
						bottom: calc(
							(var(--kite-input-layout-#{$size}-min-size) / 2) -
								(
									var(--kite-input-layout-#{$size}-border-width-focused) - var(
											--kite-input-layout-#{$size}-border-width-default
										)
								)
						);
					}
				}

				.label {
					bottom: calc((var(--kite-input-layout-#{$size}-min-size) / 2));
					padding-left: var(--kite-input-content-#{$size}-input-padding-horizontal);
					padding-right: var(--kite-input-content-#{$size}-input-padding-horizontal);
				}

				.control-addon {
					padding: var(--kite-input-content-#{$size}-control-padding);
				}

				.input::placeholder,
				.input:not(:focus):placeholder-shown ~ .label,
				.input:read-only:placeholder-shown ~ .label {
					@include input-placeholder-font-style($size);
				}

				@include formGroup.border-radius-size('input', $size);
			}

			.hintSize#{$infix}-#{$size} {
				margin-top: var(--kite-input-layout-#{$size}-gap);
			}
		}
	}
}

.main {
	position: relative;
	display: flex;
	flex: auto;
	height: 100%;
}

.addon {
	flex: 0 0 auto;
	align-self: center;
}

.input {
	width: 100%;
	color: var(--kite-input-content-enabled-value);
	background-color: transparent;
	caret-color: var(--kite-input-cursor);
	padding: 0;
	border: 0;
	outline: 0;

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&.error,
	&:invalid {
		color: var(--kite-input-content-error-value);

		&::placeholder {
			color: var(--kite-input-content-error-placeholder);
		}
	}

	&::placeholder {
		opacity: 0;
		color: var(--kite-input-content-enabled-placeholder);
	}

	&:focus {
		&::placeholder {
			opacity: 1;
		}

		&:read-only {
			&::placeholder {
				opacity: 0;
			}
		}
	}

	&:disabled {
		color: var(--kite-input-content-disabled-value);

		&::placeholder {
			color: var(--kite-input-content-disabled-placeholder);
		}
	}

	&:not(:disabled, :focus, :read-only) {
		cursor: pointer;
	}

	&:read-only {
		cursor: initial;
	}

	&:-webkit-autofill ~ .label,
	&:autofill ~ .label {
		display: none;
	}

	&:not(:focus):placeholder-shown ~ .label,
	&:read-only:placeholder-shown ~ .label {
		bottom: 50%;
		transform: translateY(50%);

		&.with-placeholder:not(.with-label) {
			display: inline;
		}
	}
}

.label {
	position: absolute;
	left: 0;
	color: var(--kite-input-content-enabled-label);
	pointer-events: none;

	&:not(.with-label) {
		display: none;
	}

	&.error {
		color: var(--kite-input-content-error-label);
	}

	&.disabled {
		color: var(--kite-input-content-disabled-label);
	}
}

.control-addon {
	align-items: center;
	justify-content: center;
	border: none;
	background: none;
	cursor: pointer;

	&:hover {
		.clear-icon {
			color: var(--kite-rich-icon-icon-primary);
		}
	}
}

// у всех селектором внутри должна быть одинаковыя специфичность,
// поэтому где-то добавляются избыточные спецификаторы (:not(:focus-within) или .control-addon)
@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $size in $size-list {
			.root {
				&:focus-within {
					.control-addon#{$infix}-all {
						display: flex;
					}
				}

				&:not(:focus-within) {
					.control-addon#{$infix}-all {
						display: none;
					}
				}

				.control-addon.control-addon#{$infix}-force {
					display: flex;
				}

				.control-addon.control-addon#{$infix}-hidden {
					display: none;
				}
			}

			.root-empty {
				.control-addon.control-addon#{$infix}-value {
					display: none;
				}

				&:not(:focus-within) {
					.control-addon#{$infix}-value {
						display: none;
					}
				}
			}

			.root-not-empty {
				&:focus-within {
					.control-addon#{$infix}-value {
						display: flex;
					}
				}

				&:not(:focus-within) {
					.control-addon#{$infix}-value {
						display: none;
					}
				}
			}
		}
	}
}

.truncate {
	@include utils.truncate();
	white-space: nowrap;
}
