@use '~@tutu/mother-of-tokens/styles/main-layout';
@use 'sass/helpers.scss' as utils;

$grid-columns: 12;

@mixin make-grid($columns: $grid-columns, $breakpoints: utils.$grid-breakpoints) {
	@each $breakpoint in map-keys($breakpoints) {
		$infix: utils.breakpoint-infix($breakpoint, $breakpoints);

		@include utils.media-breakpoint-up($breakpoint, $breakpoints) {
			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					grid-column: span $i;
				}
			}
		}
	}
}

.col {
	grid-column: span $grid-columns;
}

@include make-grid();
