.link {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&:active {
		opacity: 0.6;
	}
}
