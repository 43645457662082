@use '~@tutu/mother-of-tokens/styles/label-value-list.scss';
@use '~@tutu/mother-of-tokens/styles/text.scss';
@use 'sass/helpers.scss' as utils;

.labelValueList {
	display: flex;
	flex-direction: column;

	&.default {
		gap: var(--kite-label-value-list-default-gap);
	}

	&.control {
		gap: var(--kite-label-value-list-control-gap);
		padding-top: var(--kite-label-value-list-control-padding-vertical);
		padding-bottom: var(--kite-label-value-list-control-padding-vertical);
	}

	&.labelOnly {
		display: block;
	}

	&:not(:global(.kite-label-value-list-emphasized)) {
		color: var(--kite-text-secondary);
	}
}

$variant-list: (small, plain), (small, small), (small, medium), (small, large), (medium, plain), (medium, small),
	(medium, medium), (medium, large), (large, plain), (large, small), (large, medium), (large, large);

$sizeContrastToLabelVariant: (
	'emphasized': (
		'small': (
			'plain': 'paragraph-small',
			'small': 'paragraph-medium-compact',
			'medium': 'paragraph-large-compact',
			'large': 'header-medium',
		),
		'medium': (
			'plain': 'paragraph-medium-compact',
			'small': 'paragraph-large-compact',
			'medium': 'header-small',
			'large': 'header-medium',
		),
		'large': (
			'plain': 'paragraph-large-compact',
			'small': 'header-small',
			'medium': 'header-medium',
			'large': 'header-x-large',
		),
	),
	'default': (
		'small': (
			'plain': 'paragraph-small',
			'small': 'paragraph-small',
			'medium': 'paragraph-small',
			'large': 'paragraph-small',
		),
		'medium': (
			'plain': 'paragraph-medium-compact',
			'small': 'paragraph-medium-compact',
			'medium': 'paragraph-medium-compact',
			'large': 'paragraph-medium-compact',
		),
		'large': (
			'plain': 'paragraph-large-compact',
			'small': 'header-small',
			'medium': 'header-medium',
			'large': 'header-x-large',
		),
	),
);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $align, $textAlign in (start, left), (center, center), (end, right) {
			.align#{$infix}-#{$align} {
				align-items: #{$align};

				&.labelOnly {
					text-align: #{$textAlign};
				}
			}
		}

		@each $size, $contrast in $variant-list {
			.default.sizeContrast#{$infix}-#{$size}-#{$contrast} {
				padding-top: var(--kite-label-value-list-default-padding-vertical-size-#{$size}-contrast-#{$contrast});
				padding-bottom: var(
					--kite-label-value-list-default-padding-vertical-size-#{$size}-contrast-#{$contrast}
				);

				&.labelOnly {
					padding-top: calc(
						var(--kite-label-value-list-default-padding-vertical-size-#{$size}-contrast-#{$contrast}) +
							var(--kite-label-value-list-from-label-padding-top)
					);
					padding-bottom: calc(
						var(--kite-label-value-list-default-padding-vertical-size-#{$size}-contrast-#{$contrast}) +
							var(--kite-label-value-list-from-label-padding-bottom)
					);
				}
			}
		}

		@each $labelType, $sizes in $sizeContrastToLabelVariant {
			@each $size, $contrasts in $sizes {
				@each $contrast, $labelVariant in $contrasts {
					.label-#{$labelType}#{$infix}-#{$size}-#{$contrast} {
						--kite-label-value-list-from-label-padding-top: var(
							--kite-label-value-#{$labelVariant}-default-padding-top
						);
						--kite-label-value-list-from-label-padding-bottom: var(
							--kite-label-value-#{$labelVariant}-default-padding-bottom
						);
					}
				}
			}
		}
	}
}
