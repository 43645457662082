@use '~@tutu/mother-of-tokens/styles/flex-button.scss';

$sizes: 500, 600;

@mixin button-style {
	text-decoration: none;
	cursor: pointer;
	font-weight: 600;
	&:hover .label {
		text-decoration: underline;
	}
	&:active .label {
		text-decoration: none;
	}
	&:active {
		opacity: 0.6;
	}
}

.flexAnchor {
	@include button-style;
}

.flexButton {
	appearance: none;
	border: none;
	background: none;
	padding-inline: 0;
	padding-block: 0;

	@include button-style;
}

@each $size in $sizes {
	.layout-#{$size} {
		display: inline-flex;
		align-items: center;
		gap: var(--kite-flex-button-#{$size}-gap);
		height: 100%;
		min-height: var(--kite-flex-button-#{$size}-min-height);
	}
}
