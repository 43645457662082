@use '../options' as *;
@use '../common' as *;
// Container mixins

@mixin make-container($gutter: $container-padding-x) {
	--#{$prefix}gutter-x: #{$gutter};
	--#{$prefix}gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--#{$prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
	padding-left: calc(var(--#{$prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;
}
