@use 'sass/helpers.scss' as utils;
@use '../FormGroup/form-group.scss' as formGroup;

@use '~@tutu/mother-of-tokens/styles/button.scss';
@use '~@tutu/mother-of-tokens/styles/public/sizing.scss';

$colors: primary, secondary, secondary-neutral, flat, contrast-high, contrast-high-neutral, contrast-low,
contrast-low-neutral, youtube, vk, viber, odnoklassniki, telegram, apple, google;
$outline-general: outline-primary, outline-neutral;
$sizes: 200, 300, 400, 500, 600, 700;

$loader-sizes: (
	700: sizing.$size-350,
	600: sizing.$size-300,
	500: sizing.$size-250,
	400: sizing.$size-200,
	300: sizing.$size-150,
	200: sizing.$size-100,
);

$addon-sizes: (
	700: 350,
	600: 250,
	500: 200,
	400: 200,
	300: 150,
	200: 100,
);
$content-types: (
	general: (
		primary,
		secondary,
		secondary-neutral,
		flat,
		outline-primary,
		outline-neutral,
	),
	special: (
		contrast-high,
		contrast-high-neutral,
		contrast-low,
		contrast-low-neutral,
		outline-form,
	),
	'other-brand': (
		youtube,
		vk,
		viber,
		odnoklassniki,
		telegram,
		apple,
		google,
	),
);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in $sizes {
			.gap#{$infix}-#{$size} {
				gap: var(--kite-button-content-#{$size}-with-addon-gap);
			}

			// Layout
			.layout#{$infix}-#{$size} {
				min-height: var(--kite-button-layout-#{$size}-min-size);
				min-width: var(--kite-button-layout-#{$size}-min-size);
				border-radius: var(--kite-button-layout-#{$size}-border-radius);
				--kite-rich-icon-500-background-size: var(
						--kite-rich-icon-#{map-get($addon-sizes, $size)}-background-size
				);

				@include formGroup.border-radius-size('button', $size);
			}

			// Loader
			.loader#{$infix}-#{$size} {
				height: map-get($loader-sizes, $size);
				width: map-get($loader-sizes, $size);
			}

			.contentIcon#{$infix}-#{$size} {
				padding: 0 calc(
					var(--kite-button-content-#{$size}-icon-padding-horizontal) +
					var(--kite-button-layout-#{$size}-padding-horizontal)
				);
			}

			$contentLayoutHorizontalPadding: calc(
				var(--kite-button-content-#{$size}-default-padding-horizontal) +
				var(--kite-button-layout-#{$size}-padding-horizontal)
			);

			.contentDefault#{$infix}-#{$size} {
				padding: 0 $contentLayoutHorizontalPadding var(--kite-button-content-#{$size}-default-list-padding-bottom);
			}

			.contentWithAddonList#{$infix}-#{$size} {
				padding: 0 calc(
					var(--kite-button-content-#{$size}-with-addon-padding-horizontal) +
					var(--kite-button-layout-#{$size}-padding-horizontal)
				);
			}

			.contentListWithAddon#{$infix}-#{$size} {
				padding: 0 var(--kite-button-content-#{$size}-with-addon-list-padding-right) var(--kite-button-content-#{$size}-with-addon-list-padding-bottom) var(--kite-button-content-#{$size}-with-addon-list-padding-left);
				min-height: var(--kite-button-content-#{$size}-with-addon-list-min-height);
			}

			.contentListDefault#{$infix}-#{$size} {
				padding-bottom: var(--kite-button-content-#{$size}-default-list-padding-bottom);
				min-height: var(--kite-button-content-#{$size}-default-list-min-height);
			}

			.buttonDefault#{$infix}-#{$size} {
				padding: 0 $contentLayoutHorizontalPadding var(--kite-button-content-#{$size}-default-list-padding-bottom);
			}
		}
	}
	.addon-#{$breakpoint} {
		display: none;
	}

	@include utils.media-breakpoint-up($breakpoint) {
		.addon-#{$breakpoint} {
			display: block;
		}
	}
}


.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	background-color: inherit;
	position: relative;

	&.link {
		.label {
			&.buttonSingleLineText {
				min-height: inherit;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}


	@each $type in map-keys($content-types) {
		@each $color in map-get($content-types, $type) {
			&.#{$type}-#{$color} {
				@if str-index($color, 'outline') == null {
					background-color: var(--kite-button-#{$type}-background-#{$color}-default);
				}
				color: var(--kite-button-#{$type}-content-#{$color}-default-label-text);
				--kite-rich-icon-icon-primary: var(--kite-button-#{$type}-content-#{$color}-default-addon);

				.label {
					color: var(--kite-button-#{$type}-content-#{$color}-default-label-text);
				}

				.value {
					color: var(--kite-button-#{$type}-content-#{$color}-default-value-text);
				}

				.sublabel {
					color: var(--kite-button-#{$type}-content-#{$color}-default-subtitle-text);
				}

				.subvalue {
					color: var(--kite-button-#{$type}-content-#{$color}-default-subvalue-text);
				}

				&:hover {
					@if str-index($color, 'outline') == null {
						background-color: var(--kite-button-#{$type}-background-#{$color}-hover);
					}
					color: var(--kite-button-#{$type}-content-#{$color}-hover-label-text);
					--kite-rich-icon-icon-primary: var(--kite-button-#{$type}-content-#{$color}-hover-addon);

					.label {
						color: var(--kite-button-#{$type}-content-#{$color}-hover-label-text);
					}

					.value {
						color: var(--kite-button-#{$type}-content-#{$color}-hover-value-text);
					}

					.sublabel {
						color: var(--kite-button-#{$type}-content-#{$color}-hover-subtitle-text);
					}

					.subvalue {
						color: var(--kite-button-#{$type}-content-#{$color}-hover-subvalue-text);
					}
				}

				&.disabled {
					@if str-index($color, 'outline') == null {
						background-color: var(--kite-button-#{$type}-background-#{$color}-disabled);
					}
					color: var(--kite-button-#{$type}-content-#{$color}-disabled-label-text);
					--kite-rich-icon-icon-primary: var(--kite-button-#{$type}-content-#{$color}-disabled-addon);

					.label {
						color: var(--kite-button-#{$type}-content-#{$color}-disabled-label-text);
					}

					.value {
						color: var(--kite-button-#{$type}-content-#{$color}-disabled-value-text);
					}

					.sublabel {
						color: var(--kite-button-#{$type}-content-#{$color}-disabled-subtitle-text);
					}

					.subvalue {
						color: var(--kite-button-#{$type}-content-#{$color}-disabled-subvalue-text);
					}
				}
			}
		}
	}

	@each $color in $outline-general {
		&.general-#{$color} {
			border: var(--kite-button-border-width-general-outline-default) solid var(--kite-button-general-border-#{$color}-default);
			color: var(--kite-button-general-content-#{$color}-default-label-text);
			--kite-rich-icon-icon-primary: var(--kite-button-general-content-#{$color}-default-addon);

			.label {
				color: var(--kite-button-general-content-#{$color}-default-label-text);
			}

			.value {
				color: var(--kite-button-general-content-#{$color}-default-value-text);
			}

			.sublabel {
				color: var(--kite-button-general-content-#{$color}-default-subtitle-text);
			}

			.subvalue {
				color: var(--kite-button-general-content-#{$color}-default-subvalue-text);
			}

			&:hover {
				border: var(--kite-button-border-width-general-outline-hover) solid var(--kite-button-general-border-#{$color}-hover);
				color: var(--kite-button-general-content-#{$color}-hover-label-text);
				--kite-rich-icon-icon-primary: var(--kite-button-general-content-#{$color}-hover-addon);

				.label {
					color: var(--kite-button-general-content-#{$color}-hover-label-text);
				}

				.value {
					color: var(--kite-button-general-content-#{$color}-hover-value-text);
				}

				.sublabel {
					color: var(--kite-button-general-content-#{$color}-hover-subtitle-text);
				}

				.subvalue {
					color: var(--kite-button-general-content-#{$color}-hover-subvalue-text);
				}
			}

			&.disabled {
				border: var(--kite-button-border-width-general-outline-disabled) solid var(--kite-button-general-border-#{$color}-disabled);
				color: var(--kite-button-general-content-#{$color}-disabled-label-text);
				--kite-rich-icon-icon-primary: var(--kite-button-general-content-#{$color}-disabled-addon);

				.label {
					color: var(--kite-button-general-content-#{$color}-disabled-label-text);
				}

				.value {
					color: var(--kite-button-general-content-#{$color}-disabled-value-text);
				}

				.sublabel {
					color: var(--kite-button-general-content-#{$color}-disabled-subtitle-text);
				}

				.subvalue {
					color: var(--kite-button-general-content-#{$color}-disabled-subvalue-text);
				}
			}
		}
	}

	&.special-outline-form {
		background-color: var(--kite-button-special-background-outline-form-default);
		border: var(--kite-button-border-width-special-outline-form-default) solid var(--kite-button-special-border-outline-form-default);
		--kite-rich-icon-icon-primary: var(--kite-button-special-content-outline-form-default-addon);

		&:hover {
			background-color: var(--kite-button-special-background-outline-form-hover);
			border: var(--kite-button-border-width-special-outline-form-hover) solid var(--kite-button-special-border-outline-form-hover);
			--kite-rich-icon-icon-primary: var(--kite-button-special-content-outline-form-hover-addon);
		}

		&:disabled {
			background-color: var(--kite-button-special-background-outline-form-disabled);
			border: var(--kite-button-border-width-special-outline-form-disabled) solid var(--kite-button-special-border-outline-form-disabled);
			--kite-rich-icon-icon-primary: var(--kite-button-special-content-outline-form-disabled-addon);
		}
	}

	&.disabled {
		pointer-events: none;
	}

	&:hover {
		transition: color 0.3s ease;
	}

	&.circle {
		border-radius: 50%;
	}
}

.centeredList {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fullWidth {
	width: 100%;
}

.fullWidthList {
	flex: 1;
}

.contentListWithAddon {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.link {
	box-sizing: border-box;
	text-decoration: none;

	.buttonSingleLineText {
		padding-bottom: 0;
	}
}

.linkWidth {
	width: fit-content;
}

.pseudoLink {
	appearance: none;
	cursor: pointer;
	border: none;
	background: none;
	padding-inline: 0;
	font: inherit;

	&:hover {
		text-decoration: underline;
	}

	&:active {
		text-decoration: none;
		color: var(--kite-text-brand);
	}
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.contentWrapper {
	display: flex;
	align-items: center;
	justify-content: center;

	&.loading {
		visibility: hidden;
	}
}
