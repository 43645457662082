@use 'sass:math';
@import '~@tutu/mother-of-tokens/styles/loader.scss';
@import './keyframes.scss';

$container-to-element-coefficient: 2.7;
$relative-width: math.div(100%, $container-to-element-coefficient);
$half-width: math.div($relative-width, 2);


.loader {
	position: relative;
	height: 100%;
	width: fit-content;
	aspect-ratio: 1/1;
	overflow: hidden;

	.left,
	.right {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		animation: 1s linear 0s infinite normal loader-container;
	}

	.left {
		left: -100%;
	}

	.right {
		left: 0;
	}

	.left:before,
	.right:before,
	.left:after,
	.right:after {
		position: absolute;
		content: '';
		border-radius: 15%;
		top: calc(50% - calc($half-width));
		width: calc($relative-width);
		height: calc($relative-width);
	}

	.left:before,
	.right:before {
		left: 7%;
	}

	.left:after,
	.right:after {
		right: 7%;
	}

	.left:before {
		animation: 1s linear 0s infinite normal loader-odd-left;
	}

	.left:after {
		animation: 1s linear 0s infinite normal loader-odd-right;
	}

	.right:before {
		animation: 1s linear 0s infinite normal loader-even-left;
	}

	.right:after {
		animation: 1s linear 0s infinite normal loader-even-right;
	}

	@each $cName in ('light', 'dark', 'color', 'brand') {
		&.#{$cName} {
			.left:before,
			.right:before {
				background-color: var(--kite-loader-on-#{$cName}-left);
			}

			.left:after,
			.right:after {
				background-color: var(--kite-loader-on-#{$cName}-right);
			}
		}
	}
}
