$icon_duration: 200ms;
$content_duration: 300ms;

$interpolation: cubic-bezier(0.45, 0, 0.55, 1);
$max_height: 1000px;

.accordion {
	& > summary {
		cursor: pointer;
		list-style: none;
	}

	& > summary::-webkit-details-marker {
		display: none;
	}

	.summaryForFlexButton {
		display: inline-block;
	}

	.chevronAnimation i {
		transition-property: transform;
		transition-duration: $icon_duration;
		transition-timing-function: $interpolation;
		transform-origin: center center;
	}

	&[open] .chevronAnimation i {
		transform: rotate(-180deg);
	}

	.content {
		position: relative;
	}

	&[open] .content {
		animation: animOpen $content_duration $interpolation;
	}

	&.closing .chevronAnimation i {
		transform: rotate(0);
	}

	&.closing .content {
		animation: animClose $content_duration $interpolation;
	}

	& > summary > * {
		pointer-events: none;
	}
}

@keyframes animOpen {
	from {
		transform: translateY(-8px);
		opacity: 0;
		max-height: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
		max-height: $max_height;
	}
}

@keyframes animClose {
	from {
		transform: translateY(0);
		opacity: 1;
		max-height: $max_height;
	}
	to {
		transform: translateY(-8px);
		opacity: 0;
		max-height: 0;
	}
}
