@use '~@tutu/mother-of-tokens/styles/bulleted-list.scss';
@use '~@tutu/mother-of-tokens/styles/public/typography.scss';
@use '~@tutu/mother-of-tokens/styles/label-value.scss';
@use '~@tutu/mother-of-tokens/styles/public/semantic-colors.scss';
@use '../../../helpers/semanticColors/style.scss' as semantic;

@use 'sass/helpers.scss' as utils;

$prefix: '--kite-bulleted-list-';

.bulletedList {
	position: relative;
	&.ul {
		list-style: none;
	}
	&.ul > li {
		position: relative;
		// Marker
		&::before {
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 0;
			content: '–';
			width: var(#{$prefix}bullet-min-width);
			min-width: var(#{$prefix}bullet-min-width);
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
	&.ol {
		list-style: none;
		counter-reset: list-counter;
	}
	&.ol > li {
		position: relative;
		counter-increment: list-counter;
		// Marker
		&::before {
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 0;
			content: counter(list-counter) '. ';
			width: var(#{$prefix}bullet-min-width);
			min-width: var(#{$prefix}bullet-min-width);
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	@each $value in semantic.$content-colors {
		&>li.text-#{$value} {
			color: var(--kite-content-#{$value});
		}
	}
}

@mixin markerFont($size) {
	@if $size == large {
		@include typography.compact-600-numeric;
		padding-top: var(--kite-label-value-paragraph-#{$size}-compact-default-padding-top);
		padding-bottom: var(--kite-label-value-paragraph-#{$size}-compact-default-padding-bottom);
		min-height: var(--kite-label-value-paragraph-#{$size}-compact-default-min-height);
	} @else if $size == medium {
		@include typography.compact-500-numeric;
		padding-top: var(--kite-label-value-paragraph-#{$size}-compact-default-padding-top);
		padding-bottom: var(--kite-label-value-paragraph-#{$size}-compact-default-padding-bottom);
		min-height: var(--kite-label-value-paragraph-#{$size}-compact-default-min-height);
	} @else if $size == small {
		@include typography.compact-400-numeric;
		padding-top: var(--kite-label-value-paragraph-#{$size}-default-padding-top);
		padding-bottom: var(--kite-label-value-paragraph-#{$size}-default-padding-bottom);
		min-height: var(--kite-label-value-paragraph-#{$size}-default-min-height);
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in small, medium, large {
			.size#{$infix}-#{$size} {
				&.bulletedList {
					padding-bottom: var(#{$prefix}layout-#{$size}-padding-bottom);

					& > li {
						padding-left: calc(
							var(#{$prefix}bullet-min-width) + var(#{$prefix}layout-#{$size}-gap-horizontal)
						);
						margin-bottom: var(#{$prefix}layout-#{$size}-gap-vertical);
						&:last-child {
							margin-bottom: 0;
						}
					}

					&.ul > li {
						// Marker
						&::before {
							padding-right: var(#{$prefix}bullet-unordered-#{$size}-padding-right);
							@include markerFont($size);
						}
					}

					&.ol > li {
						// Marker
						&::before {
							@include markerFont($size);
						}
					}
				}
			}
		}
	}
}
