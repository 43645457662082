@use '~@tutu/mother-of-tokens/styles/status-block.scss';
@use 'sass/helpers.scss' as utils;

.statusBlock {
	display: flex;
	flex-direction: column;

	&.layout-center {
		align-items: center;

		& .bottomAddon {
			justify-content: center;
			flex-direction: column;
		}
	}

	&.layout-left {
		align-items: start;

		& .bottomAddon {
			justify-content: start;
		}
	}

	& .bottomAddon {
		display: flex;
		flex-wrap: wrap;
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		$gapSize: if($breakpoint == xs or $breakpoint == sm, 'x-small', 'medium');

		.gap {
			& .topAddon {
				margin-bottom: var(--kite-status-block-#{$gapSize}-content-gap);
			}

			& .content {
				margin-bottom: var(--kite-status-block-#{$gapSize}-layout-gap);
			}

			& .bottomAddon {
				gap: var(--kite-status-block-#{$gapSize}-addon-bottom-gap);
			}
		}
	}
}
