@use '~@tutu/mother-of-tokens/styles/public/semantic-colors.scss';
@use '~@tutu/mother-of-tokens/styles/badge.scss';
@use '../../../helpers/semanticColors/style.scss' as semantic;

$size-list: (100, 200, 300, 400);

$wave-border-width: (
	100: 6px,
	200: 7px,
	300: 8px,
	400: 9px,
);

$tilt-border-width: (
	100: 9px,
	200: 10px,
	300: 11px,
	400: 13px,
);

@mixin badgeSideElementsSettings($width) {
	&Left {
		@each $size in $size-list {
			&#{$size} {
				right: calc(100% - map-get($width, $size));
				width: map-get($width, $size);
			}
		}
	}

	&Right {
		transform: rotate(180deg);

		@each $size in $size-list {
			&#{$size} {
				left: calc(100% - map-get($width, $size));
				width: map-get($width, $size);
			}
		}
	}
}

.badgeWrapper {
	position: relative;
	width: fit-content;

	.badgeAddon {
		display: flex;
		align-items: center;
	}

	.badgeValue {
		align-items: end;
	}

	@each $colorStyle in (overlay, background) {
		// Опираемся на $overlay-colors потому что он идентичен с $background-colors
		@each $overlayColor in semantic.$overlay-colors {
			$overlayColorVar: var(--kite-#{$colorStyle}-#{$overlayColor});

			.badgePanelColor-#{$colorStyle}-#{$overlayColor} {
				background-color: #{$overlayColorVar};
			}

			.badgeBorder-#{$colorStyle}-#{$overlayColor} path {
				fill: #{$overlayColorVar};
			}
		}
	}

	@each $size in $size-list {
		.badgePanel-#{$size} {
			min-height: var(--kite-badge-layout-#{$size}-min-height);
			border-radius: var(--kite-badge-layout-#{$size}-border-radius);
			gap: var(--kite-badge-layout-#{$size}-gap);
			padding: 0 var(--kite-badge-layout-#{$size}-padding-horizontal-default);

			&PositionTop {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}

			&PositionBottom {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}

		.badgeValue-#{$size} {
			padding: 0
				var(--kite-badge-label-#{$size}-padding-horizontal)
				var(--kite-badge-label-#{$size}-padding-bottom)
				var(--kite-badge-label-#{$size}-padding-horizontal);
			white-space: nowrap;
			z-index: 1;
		}
	}

	& .badgePanel {
		display: flex;
		align-items: center;

		&PositionTop {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&PositionBottom {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}

.badgeBorderWave {
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	width: fit-content;

	@include badgeSideElementsSettings($wave-border-width);
}

@each $size in $size-list {
	.badgeWrapperWave-#{$size} {
		padding: 0 map-get($wave-border-width, $size);

		& .badgePanel {
			padding: 0;
			border-radius: 0;
		}
	}
}

.badgeWrapperWave .badgePanel {
	padding: 0;
	border-radius: 0;
}

.badgeBorderTilt {
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	width: fit-content;

	@include badgeSideElementsSettings($tilt-border-width);
}

@each $size in $size-list {
	.badgeWrapperTilt-#{$size} {
		padding: 0 map-get($tilt-border-width, $size);

		& .badgePanel {
			padding: 0;
			border-radius: 0;
		}
	}
}
