@use '~@tutu/mother-of-tokens/styles/carousel.scss';
@use '~@tutu/mother-of-tokens/styles/main-layout';
@use '~@tutu/mother-of-tokens/styles/public/shadow.scss';
@use 'sass/helpers.scss' as utils;

@import '~@tutu/mother-of-tokens/styles/public/spacing';

$interpolation: cubic-bezier(0, 0, 0.58, 1);
$duration: 300ms;

.container {
	position: relative;
}

.hover-arrows {
	opacity: 0;
	transition-property: opacity;
	transition-duration: $duration;
	transition-timing-function: $interpolation;
}

.carousel {
	position: relative;
	overflow: hidden;
	user-select: none;
	box-sizing: border-box;

	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			padding: 0 calc(var(--kite-carousel-gap-#{$breakpoint}) / 2);
		}
	}

	&:hover {
		.hover-arrows {
			opacity: 1;
		}
	}
}

.single-plus .carousel {
	box-sizing: border-box;
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			padding: 0 calc(var(--kite-main-layout-margin-horizontal-#{$breakpoint}) - var(--kite-carousel-gap-#{$breakpoint}) / 2);
		}
	}
}

.rail {
	transition-property: transform;
	transition-timing-function: $interpolation;
	transition-duration: $duration;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	width: fit-content;
	align-items: stretch;

	* {
		-webkit-user-drag: none;
		user-select: none;
	}
}

.slide {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	box-sizing: border-box;
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			padding: 0 calc(var(--kite-carousel-gap-#{$breakpoint}) / 2);
		}
	}
}

.slide-single-plus {
	transition-property: transform;
	transition-timing-function: $interpolation;
	transition-duration: $duration;
	transform: scaleY(0.9);
}

.slide-single-plus.active {
	transform: scaleY(1);
}

.badge {
	position: absolute;
	bottom: var(--kite-carousel-arrow-padding-horizontal);

	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			right: calc(var(--kite-carousel-arrow-padding-horizontal) + var(--kite-carousel-gap-#{$breakpoint}));
		}
	}
}

.single-plus .badge {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			right: calc(var(--kite-carousel-arrow-padding-horizontal) + var(--kite-main-layout-margin-horizontal-#{$breakpoint}));
		}
	}
}

.arrow {
	width: $spacing-650;
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 0 var(--kite-carousel-arrow-padding-horizontal);
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			bottom: calc($spacing-200 + var(--kite-carousel-gap-#{$breakpoint}));
		}
	}
}

.pagination-hidden .arrow {
	bottom: 0;
}

.arrow.right {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			right: var(--kite-carousel-gap-#{$breakpoint});
		}
	}
}

.arrow.left {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			left: var(--kite-carousel-gap-#{$breakpoint});
		}
	}
}

.single-plus .arrow.left {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			left: var(--kite-main-layout-margin-horizontal-#{$breakpoint});
		}
	}
}

.single-plus .arrow.right {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			right: var(--kite-main-layout-margin-horizontal-#{$breakpoint});
		}
	}
}

.arrow.right.outside {
	padding: 0;
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			right: calc(var(--kite-carousel-gap-#{$breakpoint}) - $spacing-400);
		}
	}
}

.arrow.left.outside {
	padding: 0;
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			left: calc(var(--kite-carousel-gap-#{$breakpoint}) - $spacing-400);
		}
	}
}

.single-plus .arrow.right.outside {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			right: calc(var(--kite-main-layout-margin-horizontal-#{$breakpoint}) - $spacing-400);
		}
	}
}

.single-plus .arrow.left.outside {
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			left: calc(var(--kite-main-layout-margin-horizontal-#{$breakpoint}) - $spacing-400);
		}
	}
}

.shadow {
	box-shadow: var(--kite-shadow-500);
}

.bar {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			height: calc($spacing-200 + var(--kite-carousel-gap-#{$breakpoint}));
			padding-top: var(--kite-carousel-gap-#{$breakpoint});
		}
	}
}

.no-animation * {
	transition: none !important;
}
