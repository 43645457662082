.image {
	max-width: 100%;
	object-fit: cover;
}

.ratio1x1 {
	aspect-ratio: 1 / 1;
}

.ratio3x2 {
	aspect-ratio: 3 / 2;
}

.ratio3x4 {
	aspect-ratio: 3 / 4;
}
