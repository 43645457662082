@use '~@tutu/mother-of-tokens/styles/segmented-control';
@use '~@tutu/mother-of-tokens/styles/public/shadow';

$prefix: '--kite-segmented-control-item-layout';
$sizes: 400, 500, 600;

.segmentControlItem {
	cursor: pointer;
	user-select: none;
	flex: 1 1 0;
	background: var(--kite-segmented-control-item-background-default);

	&.hug {
		flex: 1.1 1 0;
	}

	.text {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}

	.label {
		text-transform: capitalize;
		color: var(--kite-segmented-control-item-label-text-default);
	}

	.subtitle {
		color: var(--kite-segmented-control-item-subtitle-text-default);
	}

	&.selected {
		cursor: auto;
		color: var(--kite-segmented-control-item-label-text-selected);
		background: var(--kite-segmented-control-item-background-selected);
		box-shadow: var(--kite-shadow-300);
	}

	&:hover:not(.selected) {
		.label {
			color: var(--kite-segmented-control-item-label-text-hover);
		}

		.subtitle {
			color: var(--kite-segmented-control-item-subtitle-text-hover);
		}

		background: var(--kite-segmented-control-item-background-hover);
	}
}

@each $size in $sizes {
	.layout-item-#{$size} {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: var(--kite-segmented-control-item-layout-#{$size}-gap);
		min-height: var(#{$prefix}-#{$size}-min-size);
		padding-left: var(#{$prefix}-#{$size}-padding-horizontal);
		padding-right: var(#{$prefix}-#{$size}-padding-horizontal);
		border-radius: var(#{$prefix}-#{$size}-border-radius);
	}
}
