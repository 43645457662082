@use '~@tutu/mother-of-tokens/styles/main-layout.scss';
@use '~@tutu/mother-of-tokens/styles/text.scss';

ul,
ol {
	margin: 0;
	padding: 0;
}

html,
html#{&} {
	-webkit-text-size-adjust: 100%;
}

html[data-theme],
html[data-theme][data-style] {
	background-color: var(--kite-main-layout-color);
}

[data-ds='kite'] {
	body,
	body#{&} {
		color: var(--kite-text-primary, black);
		background-color: var(--kite-main-layout-color);
		margin: 0;
	}
}

details,
details#{&},
main,
main#{&} {
	display: block;
}

h1,
h1#{&} {
	margin: 0.67em 0;
	font-size: 2em;
}

hr,
hr#{&} {
	height: 0;
	overflow: visible;
	box-sizing: content-box;
}

abbr[title],
abbr[title]#{&} {
	border-bottom: none;
	text-decoration: underline dotted;
}

button,
button#{&},
input,
input#{&},
optgroup,
optgroup#{&},
select,
select#{&},
textarea,
textarea#{&} {
	margin: 0;
}

button,
button#{&},
input,
input#{&} {
	overflow: visible;
}

button,
button#{&},
select,
select#{&} {
	text-transform: none;
}

[type='button'],
[type='button']#{&},
[type='reset'],
[type='reset']#{&},
[type='submit'],
[type='submit']#{&},
button,
button#{&} {
	-webkit-appearance: button;
}

[type='button']::-moz-focus-inner,
[type='button']::-moz-focus-inner#{&},
[type='reset']::-moz-focus-inner,
[type='reset']::-moz-focus-inner#{&},
[type='submit']::-moz-focus-inner,
[type='submit']::-moz-focus-inner#{&},
button::-moz-focus-inner,
button::-moz-focus-inner#{&} {
	padding: 0;
	border-style: none;
}

[type='button']:-moz-focusring,
[type='button']:-moz-focusring#{&},
[type='reset']:-moz-focusring,
[type='reset']:-moz-focusring#{&},
[type='submit']:-moz-focusring,
[type='submit']:-moz-focusring#{&},
button:-moz-focusring,
button:-moz-focusring#{&} {
	outline: 0;
}

fieldset,
fieldset#{&} {
	padding: 0.35em 0.75em 0.625em;
}

legend,
legend#{&} {
	color: inherit;
	display: table;
	padding: 0;
	max-width: 100%;
	box-sizing: border-box;
	white-space: normal;
}

progress,
progress#{&} {
	vertical-align: baseline;
}

textarea,
textarea#{&} {
	overflow: auto;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-inner-spin-button#{&},
[type='number']::-webkit-outer-spin-button,
[type='number']::-webkit-outer-spin-button#{&} {
	height: auto;
}

[type='search'],
[type='search']#{&} {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-decoration#{&} {
	-webkit-appearance: none;
}

::-webkit-file-upload-button,
::-webkit-file-upload-button#{&} {
	font: inherit;
	-webkit-appearance: button;
}

summary,
summary#{&} {
	display: list-item;
}

template,
template#{&} {
	display: none;
}

a,
a#{&},
button,
button#{&},
input,
input#{&},
a:focus-ring,
a:focus-ring#{&},
button:focus-ring,
button:focus-ring#{&},
input:focus-ring,
input:focus-ring#{&} {
	outline: 0;
}
