@import '~@tutu/mother-of-tokens/styles/switch.scss';

.input-wrapper {
	position: relative;
	display: inline-block;
	width: var(--kite-switch-width);
	height: var(--kite-switch-height);
	&.error {
		border-radius: var(--kite-switch-border-radius);
		box-shadow: 0 0 0 var(--kite-switch-error-border-width) var(--kite-switch-error-border);
	}
}

.input {
	appearance: none;
	display: inline-block;
	margin: unset;
	width: 100%;
	height: 100%;

	&:not(:disabled) {
		cursor: pointer;
	}

	&:checked {
		& ~ .thumb-wrapper {
			background-color: var(--kite-switch-checked-default-background);
			.thumb {
				left: unset;
				right: 2px;
				background-color: var(--kite-switch-checked-default-knob);
			}
		}
	}

	&:disabled {
		& ~ .thumb-wrapper {
			background-color: var(--kite-switch-unchecked-disabled-background);
			.thumb {
				background-color: var(--kite-switch-unchecked-disabled-knob);
			}
		}
		&:checked {
			& ~ .thumb-wrapper {
				background-color: var(--kite-switch-checked-disabled-background);
				.thumb {
					background-color: var(--kite-switch-checked-disabled-knob);
				}
			}
		}
	}

	&:not(:disabled):hover {
		& ~ .thumb-wrapper {
			background-color: var(--kite-switch-unchecked-hover-background);
			.thumb {
				background-color: var(--kite-switch-unchecked-hover-knob);
			}
		}
		&:checked {
			& ~ .thumb-wrapper {
				background-color: var(--kite-switch-checked-hover-background);
				.thumb {
					background-color: var(--kite-switch-checked-hover-knob);
				}
			}
		}
	}
}

.thumb-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	cursor: pointer;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: var(--kite-switch-unchecked-default-background);
	border-radius: var(--kite-switch-border-radius);

	.thumb {
		position: absolute;
		top: var(--kite-switch-padding);
		left: var(--kite-switch-padding);
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: var(--kite-switch-unchecked-default-knob);
	}
}
