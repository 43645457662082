@function repeat($character, $n, $withSpace) {
	$c: '';
	@for $i from 1 through $n {
		$c: $c + $character;

		@if $withSpace {
			$c: $c + ' ';
		}
	}

	@return $c;
}

@each $colorSchema in 'light', 'dark' {
	$inverted: '[data-theme="inverted"]';
	$light: '[data-theme="light"]';
	$dark: '[data-theme="dark"]';
	$root: ':root, ';

	$prop: '#{if($colorSchema == 'light', $root, '')}';

	$prop: $prop +
		'[data-theme=#{$colorSchema}], ' +
		'[data-theme=#{$colorSchema}]' +
		#{repeat($inverted, 2, true)} +
		', ';

	$prop: $prop + "#{if($colorSchema == 'light', $dark, $light)} " + #{repeat($inverted, 1, true)} + ', ';
	$prop: $prop + "#{if($colorSchema == 'light', $dark, $light)} " + #{repeat($inverted, 3, true)} + ', ';

	#{$prop} {
		@if $colorSchema == 'light' {
			--kite-scoped-theme: light;
		} @else {
			--kite-scoped-theme: dark;
		}
	}
}
