@use 'sass/helpers.scss' as utils;
@use '~@tutu/mother-of-tokens/styles/dropdown.scss';
@use '~@tutu/mother-of-tokens/styles/public/shadow.scss';

$sizes: 500, 600, 700;
$shadows: 500, 700;

$maxWidthDefault: initial;
$maxHeightDefault: initial;

.panel {
	box-sizing: border-box;

	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		--dropdown-max-width-#{$breakpoint}: #{$maxWidthDefault};
		$maxWidthDefault: var(--dropdown-max-width-#{$breakpoint});
		--dropdown-max-height-#{$breakpoint}: #{$maxHeightDefault};
		$maxHeightDefault: var(--dropdown-max-height-#{$breakpoint});

		@include utils.media-breakpoint-up($breakpoint) {
			@each $shadow in $shadows {
				&.shadow#{$infix}-#{$shadow} {
					box-shadow: var(--kite-shadow-#{$shadow});
				}
			}

			max-width: var(--dropdown-max-width-#{$breakpoint});
			max-height: var(--dropdown-max-height-#{$breakpoint});

			@each $value in $sizes {
				&.size#{$infix}-#{$value} {
					&.standalone {
						padding: var(--kite-dropdown-common-padding-vertical)
							calc(
								var(--kite-dropdown-common-padding-horizontal) +
									var(--kite-dropdown-#{$value}-inner-padding-horizontal)
							);
					}

					.item {
						border-radius: var(--kite-dropdown-#{$value}-inner-radius);
						padding: 0 var(--kite-dropdown-#{$value}-inner-padding-horizontal);
						margin-right: calc(-1 * var(--kite-dropdown-#{$value}-inner-padding-horizontal));
						margin-left: calc(-1 * var(--kite-dropdown-#{$value}-inner-padding-horizontal));
					}
				}
			}
		}
	}

	&.standalone {
		overflow-y: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		scrollbar-width: thin;
	}
}

.item {
	background-color: transparent;
	margin: 0;
	cursor: pointer;

	&.focused,
	&:hover {
		background-color: var(--kite-dropdown-background-hover);
	}

	&.disabled {
		background-color: inherit;
		cursor: default;

		&:hover {
			background-color: unset;
		}
	}
}
