@use '~@tutu/mother-of-tokens/styles/slider.scss';

@mixin _thumb {
	appearance: none;
	pointer-events: all;
	width: var(--kite-slider-500-knob-size);
	height: var(--kite-slider-500-knob-size);
	background-color: var(--kite-slider-knob-default);
	border-radius: 50%;
	cursor: grab;
}

@mixin _thumbHover {
	box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-default-knob-border);
}

.slider {
	position: relative;
	height: var(--kite-slider-500-knob-size);
	width: 100%;
}

.range {
	$height: var(--kite-slider-500-background-height);
	position: absolute;
	appearance: none;
	background-color: transparent;
	height: $height;
	margin: calc((var(--kite-slider-500-knob-size) - var(--kite-slider-500-background-height)) * 0.5) 0;
	cursor: pointer;
	width: 100%;
	border-radius: calc($height * 0.5);

	&.hasTwoKnobs {
		pointer-events: none;
	}

	&.fromRange {
		z-index: 1;
		background-color: transparent;
	}

	&::-webkit-slider-container {
		min-block-size: var(--kite-slider-500-background-height);
	}

	&::-moz-range-track {
		height: var(--kite-slider-500-background-height);
		background-color: transparent;
		cursor: pointer;
		border-top: calc((var(--kite-slider-500-knob-size) - var(--kite-slider-500-background-height)) * 0.5) solid transparent;
		border-bottom: calc((var(--kite-slider-500-knob-size) - var(--kite-slider-500-background-height)) * 0.5) solid transparent;
	}

	&::-webkit-slider-runnable-track {
		height: var(--kite-slider-500-background-height);
		background-color: transparent;
		cursor: pointer;
		border-top: calc(var(--kite-slider-500-knob-size) * 0.5) solid transparent;
		border-bottom: calc(var(--kite-slider-500-knob-size) * 0.5) solid transparent;
	}

	&.fromRange::-moz-range-thumb {
		pointer-events: all;
	}

	&.fromRange::-webkit-slider-thumb {
		pointer-events: all;
	}

	&::-moz-range-thumb {
		-moz-appearance: none;
		border: none;
		@include _thumb;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		@include _thumb;
		top: calc(var(--kite-slider-500-knob-size) * -0.5);
		position: relative;
	}

	&::-moz-range-thumb:hover {
		@include _thumbHover;
	}

	&::-webkit-slider-thumb:hover {
		@include _thumbHover;
	}

	&::-webkit-slider-thumb:active {
		cursor: grabbing;
	}

	&::-moz-range-thumb:active {
		cursor: grabbing;
	}

	// Specific color classes for webkit
	&.positive::-webkit-slider-thumb {
		background-color: var(--kite-slider-knob-positive);
	}

	&.positive-low::-webkit-slider-thumb {
		background-color: var(--kite-slider-knob-positive-low);
	}

	&.warning::-webkit-slider-thumb {
		background-color: var(--kite-slider-knob-warning);
	}

	&.negative::-webkit-slider-thumb {
		background-color: var(--kite-slider-knob-negative);
	}

	&.negative-low::-webkit-slider-thumb {
		background-color: var(--kite-slider-knob-negative-low);
	}

	// Specific color classes for Firefox
	&.positive::-moz-range-thumb {
		background-color: var(--kite-slider-knob-positive);
	}

	&.positive-low::-moz-range-thumb {
		background-color: var(--kite-slider-knob-positive-low);
	}

	&.warning::-moz-range-thumb {
		background-color: var(--kite-slider-knob-warning);
	}

	&.negative::-moz-range-thumb {
		background-color: var(--kite-slider-knob-negative);
	}

	&.negative-low::-moz-range-thumb {
		background-color: var(--kite-slider-knob-negative-low);
	}

	// Hover effects for specific color classes for webkit
	&.positive::-webkit-slider-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-positive-knob-border);
	}

	&.positive-low::-webkit-slider-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-positive-low-knob-border);
	}

	&.warning::-webkit-slider-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-warning-knob-border);
	}

	&.negative::-webkit-slider-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-negative-knob-border);
	}

	&.negative-low::-webkit-slider-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-negative-low-knob-border);
	}

	// Hover effects for specific color classes for firefox
	&.positive::-moz-range-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-positive-knob-border);
	}

	&.positive-low::-moz-range-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-positive-low-knob-border);
	}

	&.warning::-moz-range-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-warning-knob-border);
	}

	&.negative::-moz-range-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-negative-knob-border);
	}

	&.negative-low::-moz-range-thumb:hover {
		box-shadow: 0 0 0 var(--kite-slider-500-knob-border-width) var(--kite-slider-hover-negative-low-knob-border);
	}
}
