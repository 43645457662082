@use 'sass/helpers.scss' as utils;

@import '~@tutu/mother-of-tokens/styles/form-group.scss';

$size-list: (500, 600, 700);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $size in $size-list {
			.size#{$infix}-#{$size} {
				--kite-form-group-gap-horizontal: var(--kite-form-group-#{$size}-gap-horizontal);
				--kite-form-group-gap-vertical: var(--kite-form-group-#{$size}-gap-vertical);
			}
		}

		@each $layout in horizontal, vertical {
			.layout#{$infix}-#{$layout} {
				--kite-form-group-gap: var(--kite-form-group-gap-#{$layout});
			}
		}

		.position#{$infix}- {
			&top {
				margin: 0 0 -1px 0;
			}

			&middle-horizontal {
				margin: 0 -1px 0 0;
			}

			&middle-vertical {
				margin: 0 0 -1px 0;
			}

			&bottom {
				margin: 0;
			}

			&left {
				margin: 0 -1px 0 0;
			}

			&right {
				margin: 0;
			}

			&single {
				margin: 0;
			}
		}

		// дублируем названия классов, чтобы повысить приоритет и эти стили точно перебили отступы от position-top и т.п.
		.margin-top#{$infix}.margin-top#{$infix} {
			margin-top: var(--kite-form-group-gap);
		}
		.margin-bottom#{$infix}.margin-bottom#{$infix} {
			margin-bottom: var(--kite-form-group-gap);
		}
		.margin-left#{$infix}.margin-left#{$infix} {
			margin: 0 0 0 var(--kite-form-group-gap);
		}
		.margin-right#{$infix}.margin-right#{$infix} {
			margin: 0 var(--kite-form-group-gap) 0 0;
		}
		.margin-all#{$infix}.margin-all#{$infix} {
			margin: var(--kite-form-group-gap);
		}
		.margin-none#{$infix}.margin-none#{$infix} {
			margin: 0;
		}
	}
}

@mixin border-radius-size($type, $size) {
	--kite-form-group-element-layout-border-radius: var(--kite-#{$type}-layout-#{$size}-border-radius);
}

.formGroupElement {
	&:hover {
		position: relative;
		z-index: 1;
	}

	&:focus-within {
		position: relative;
		z-index: 1;
	}

	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

			&.position#{$infix}- {
				&top {
					border-top-left-radius: var(--kite-form-group-element-layout-border-radius);
					border-top-right-radius: var(--kite-form-group-element-layout-border-radius);
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				&middle-horizontal {
					border-radius: 0;
				}

				&middle-vertical {
					border-radius: 0;
				}

				&bottom {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					border-bottom-right-radius: var(--kite-form-group-element-layout-border-radius);
					border-bottom-left-radius: var(--kite-form-group-element-layout-border-radius);
				}

				&left {
					border-top-left-radius: var(--kite-form-group-element-layout-border-radius);
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-bottom-left-radius: var(--kite-form-group-element-layout-border-radius);
				}

				&right {
					border-top-left-radius: 0;
					border-top-right-radius: var(--kite-form-group-element-layout-border-radius);
					border-bottom-right-radius: var(--kite-form-group-element-layout-border-radius);
					border-bottom-left-radius: 0;
				}

				&single {
					border-radius: var(--kite-form-group-element-layout-border-radius);
				}
			}
		}
	}
}
