@use '~@tutu/mother-of-tokens/styles/panel.scss';
@use '~@tutu/mother-of-tokens/styles/public/semantic-colors';

.panelChip {
	cursor: pointer;
}

[data-theme='dark'] .panelChip {
	& > div[data-contrast='high'] * {
		color: var(--kite-content-static-light-primary);
	}
}

.panelChip.primary {
	&.selected {
		background-color: var(--kite-panel-selected-background);
		box-shadow: none;
	}
}

.panelChip.secondary {
	&.selected {
		box-shadow: inset 0 0 0 var(--kite-panel-border-selected) var(--kite-panel-selected-border);
	}
}
