@use '~@tutu/mother-of-tokens/styles/text.scss';

.controllCell {
	cursor: pointer;

	&.disabled {
		cursor: default;

		:global(.kite-label-value),
		:global(.kite-label-value-list) {
			color: var(--kite-text-secondary);
		}
	}
}
