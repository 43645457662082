@use 'sass/helpers.scss' as utils;
@use '~@tutu/mother-of-tokens/styles/tile.scss';
@use '~@tutu/mother-of-tokens/styles/public/spacing.scss' as *;

$size-list: 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900;

$sizes: small, medium, large;
$rich-icon-sizes: (
	small: 300,
	medium: 600,
	large: 700,
);
$cover-image-sizes: (
	xs: -16px,
	sm: -16px,
	md: -16px,
	lg: -20px,
	xl: -28px,
	xxl: -28px,
);

$positions: top, right, bottom, left;
$positions-directions: (
	top: column,
	right: row-reverse,
	bottom: column-reverse,
	left: row,
);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in $sizes {
			.content-#{$size}#{$infix} {
				gap: var(--kite-tile-gap-#{$size});
			}

			.panel-#{$size} {
				--kite-rich-icon-500-background-size: var(
						--kite-rich-icon-#{map-get($rich-icon-sizes, $size)}-background-size
				);
				--kite-rich-icon-500-icon-size: var(--kite-rich-icon-#{map-get($rich-icon-sizes, $size)}-icon-size);
			}
		}

		@each $position in $positions {
			.position-#{$position}#{$infix} {
				flex-direction: #{map-get($positions-directions, $position)};
			}
		}

		.image-top#{$infix} {
			margin: #{map-get($cover-image-sizes, $breakpoint)} #{map-get($cover-image-sizes, $breakpoint)} 0 #{map-get(
					$cover-image-sizes,
					$breakpoint
				)};
		}

		.image-bottom#{$infix} {
			margin: 0 #{map-get($cover-image-sizes, $breakpoint)} #{map-get($cover-image-sizes, $breakpoint)} #{map-get(
					$cover-image-sizes,
					$breakpoint
				)};
		}

		.image-left#{$infix} {
			margin: #{map-get($cover-image-sizes, $breakpoint)} 0 #{map-get($cover-image-sizes, $breakpoint)} #{map-get(
					$cover-image-sizes,
					$breakpoint
				)};
		}

		.image-right#{$infix} {
			margin: #{map-get($cover-image-sizes, $breakpoint)} #{map-get($cover-image-sizes, $breakpoint)} #{map-get(
					$cover-image-sizes,
					$breakpoint
				)} 0;
		}
	}
}

.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.cover-flex {
	justify-content: space-between;
}

.start {
	text-align: start;
}

.center {
	text-align: center;
}

.end {
	text-align: end;
}

.label-value-default-start {
	span {
		text-align: start;
	}
}

.label-value-default-center {
	span {
		text-align: center;
	}
}

.label-value-default-end {
	span {
		text-align: end;
	}
}

.flex1 {
	flex: 1;
}

.relative {
	position: relative;
}

.badge {
	position: absolute;
}

.badge-corner-top-left {
	top: $spacing-200;
	left: $spacing-200;
}

.badge-corner-top-right {
	top: $spacing-200;
	right: $spacing-200;
}

.badge-corner-bottom-right {
	bottom: $spacing-200;
	right: $spacing-200;
}

.badge-corner-bottom-left {
	bottom: $spacing-200;
	left: $spacing-200;
}
