@use '~@tutu/mother-of-tokens/styles/modal.scss';
@use '~@tutu/mother-of-tokens/styles/separator.scss';
@use 'sass/helpers.scss' as utils;
@use '../../../../../helpers/spacing/spacing.scss' as spacings;

$minHeightDefault: initial;
$widthDefault: initial;
.overlayContainer {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	z-index: 10;
	background-color: var(--kite-main-layout-color);

	&.coverSlot {
		position: relative;
	}

	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		--kite-overlay-min-height-#{$breakpoint}: #{$minHeightDefault};
		$minHeightDefault: var(--kite-overlay-min-height-#{$breakpoint});

		--kite-overlay-width-#{$breakpoint}: #{$widthDefault};
		$widthDefault: var(--kite-overlay-width-#{$breakpoint});
	}
}

.header {
	flex: 0 0 auto;
}

.slotTop {
	flex: 0 0 auto;
}

.content {
	flex: 1 1 auto;
	overflow: auto;
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $value in 400, 500, 600 {
			.size#{$infix}-#{$value} {
				.content,
				.slotTop {
					padding: 0 var(--kite-modal-overlay-container-#{$value}-content-padding-horizontal);
				}

				.content {
					padding-bottom: var(--kite-modal-overlay-container-#{$value}-content-padding-bottom);
				}
			}
		}

		@each $spacing in map-keys(spacings.$sizes) {
			.coverSlot#{$infix}-#{$spacing} {
				width: calc(100% - map-get(spacings.$sizes, $spacing) * 2);
				margin: map-get(spacings.$sizes, $spacing);
			}
		}

		.overlayContainer {
			min-height: var(--kite-overlay-min-height-#{$breakpoint});

			&.type#{$infix}-modal {
				position: relative;
				max-width: 90%;
				max-height: 90%;
				height: auto;

				@each $value in small, medium {
					&.#{$value} {
						width: var(--kite-modal-modal-#{$value}-width-#{$breakpoint}, 100%);
					}
				}

				&.withWidth {
					width: var(--kite-overlay-width-#{$breakpoint});
				}
			}

			&.type#{$infix}-fullScreen {
				position: absolute;
				width: 100%;
				height: 100%;
				max-height: unset;
				top: 0;
				left: 0;
				bottom: unset;
			}

			&.type#{$infix}-bottomSheet {
				position: absolute;
				width: 100%;
				height: auto;
				max-height: 90%;
				top: unset;
				left: 0;
				bottom: 0;
			}
		}
	}
}

.withSeparator {
	border-top: var(--kite-separator-width) solid var(--kite-separator-primary-background);
}
