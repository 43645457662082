@each $count in ('odd', 'even') {
	@keyframes loader-#{$count}-left {
		// Анимация движения левого элемента
		8.75% {
			transform: scale(calc(100% - 2%)) translateX(0) skewX(0);
		}

		10% {
			transform: scale(calc(100% - 2.68%)) translateX(0) skewX(0);
		}

		11.25% {
			transform: scale(calc(100% - 4%)) translateX(0) skewX(0);
		}

		12.5% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(0);
		}

		13.75% {
			transform: scale(calc(100% - 5%)) translateX(0) skewX(0);
		}

		15% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(0);
		}

		16.25% {
			transform: scale(calc(100% - 2%)) translateX(0) skewX(0);
		}

		17.5% {
			transform: scale(calc(100% + 4%)) translateX(0) skewX(0);
		}

		20% {
			transform: scale(calc(100% + 6%)) translateX(0) skewX(0);
		}

		21.25% {
			transform: scale(calc(100% + 2%)) translateX(0) skewX(2deg);
		}

		22.5% {
			transform: scale(calc(100% + 1%)) translateX(0) skewX(2deg);
		}

		23.75% {
			transform: scale(1) translateX(0) skewX(8deg);
		}

		25%, 30% {
			transform: scale(calc(100% - 0.5%)) translateX(0) skewX(12deg);
		}

		31.25% {
			transform: scale(1) translateX(0) skewX(8deg);
		}

		32.5% {
			transform: scale(1) translateX(0) skewX(6deg);
		}

		33.75% {
			transform: scale(1) translateX(0) skewX(0deg);
		}

		35% {
			transform: scale(1) translateX(0) skewX(-8deg);
		}

		36.25% {
			transform: scale(1) translateX(0) skewX(-12deg);
		}

		37.5% {
			transform: scale(1) translateX(0) skewX(-18deg);
		}

		40% {
			transform: scale(1) translateX(0) skewX(-24deg);
		}

		41.25% {
			transform: scale(1) translateX(0) skewX(-28deg);
		}

		42.5% {
			transform: scale(1) translateX(0) skewX(-24deg);
		}

		43.75% {
			transform: scale(1) translateX(0) skewX(-20deg);
		}

		45% {
			transform: scale(1) translateX(0) skewX(-12deg);
		}

		46.25%, 68.75% {
			transform: scale(1) translateX(0) skewX(-20deg);
		}

		// Анимация движения правого элемента
		58.75% {
			transform: scale(calc(100% - 2%)) translateX(0) skewX(-20deg);
		}

		60% {
			transform: scale(calc(100% - 2.68%)) translateX(0) skewX(-20deg);
		}

		61.25% {
			transform: scale(calc(100% - 4%)) translateX(0) skewX(-20deg);
		}

		62.5% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(-20deg);
		}

		63.75% {
			transform: scale(calc(100% - 5%)) translateX(0) skewX(-20deg);
		}

		65% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(-20deg);
		}

		66.25% {
			// Начало анимации сжатия
			transform: scale(95%, calc(100% - 1%)) translateX(-5%) skewX(-18deg);
		}

		67.5% {
			transform: scale(90%, calc(100% + 2%)) translateX(-10%) skewX(-15deg);
		}

		68.75% {
			transform: scale(85%, calc(100% + 2%)) translateX(-15%) skewX(-10deg);
		}

		70% {
			// Начало анимации кручения
			transform: scale(80%, calc(100% + 2.25%)) translateX(-25%) skewX(-6deg);
		}

		71.25% {
			transform: scale(75%, calc(100% + 1.34%)) translateX(-35%) skewX(-2deg);
		}

		72.5% {
			transform: scale(70%, calc(100% + 1%)) translateX(-50%) skewX(4deg);
		}

		73.75% {
			transform: scale(65%, 1) translateX(-55%) skewX(10deg);
		}

		75% {
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
		}

		76.25% {
			// Элемент переместился влево
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
		}

		78.75% {
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
			@if ($count == 'odd') {
				transform: scale(60%, 1) translateX(60%) skewX(20deg);
			}
		}

		82.5% {
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
			@if ($count == 'odd') {
				transform: scale(60%, 1) translateX(60%) skewX(20deg);
			}
		}

		87.5% {
			transform: scale(1) translateX(0) skewX(-10deg);
			@if ($count == 'odd') {
				transform: scale(80%, 1) translateX(35%) skewX(-10deg);
			}
		}

		91.25% {
			transform: scale(1) translateX(0) skewX(-6deg);
			@if ($count == 'odd') {
				transform: scale(90%, 1) translateX(10%) skewX(-6deg);
			}
		}

		93.75% {
			transform: scale(1) translateX(0) skewX(4deg);
			@if ($count == 'odd') {
				transform: scale(1) translateX(0) skewX(4deg);
			}
		}

		95% {
			transform: scale(1) translateX(0) skewX(8deg);
			@if ($count == 'odd') {
				transform: scale(1) translateX(0) skewX(8deg);
			}
		}

		96.25%,
		100% {
			transform: scale(1) translateX(0) skewX(0deg);
		}
	}

	@keyframes loader-#{$count}-right {
		// Анимация движения правого элемента
		0%,
		18.75% {
			transform: scale(1) translateX(0) skewX(-20deg);
		}

		8.75% {
			transform: scale(calc(100% - 2%)) translateX(0) skewX(-20deg);
		}

		10% {
			transform: scale(calc(100% - 2.68%)) translateX(0) skewX(-20deg);
		}

		11.25% {
			transform: scale(calc(100% - 4%)) translateX(0) skewX(-20deg);
		}

		12.5% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(-20deg);
		}

		13.75% {
			transform: scale(calc(100% - 5%)) translateX(0) skewX(-20deg);
		}

		15% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(-20deg);
		}

		16.25% {
			// start shrinking
			transform: scale(95%, calc(100% - 1%)) translateX(-5%) skewX(-18deg);
		}

		17.5% {
			transform: scale(90%, calc(100% + 2%)) translateX(-10%) skewX(-15deg);
		}

		18.75% {
			transform: scale(85%, calc(100% + 2%)) translateX(-15%) skewX(-10deg);
		}

		20% {
			//start rotating
			transform: scale(80%, calc(100% + 2.25%)) translateX(-25%) skewX(-6deg);
		}

		21.25% {
			transform: scale(75%, calc(100% + 1.34%)) translateX(-35%) skewX(-2deg);
		}

		22.5% {
			transform: scale(70%, calc(100% + 1%)) translateX(-50%) skewX(4deg);
		}

		23.75% {
			transform: scale(65%, 1) translateX(-55%) skewX(10deg);
		}

		25% {
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
		}

		26.25% {
			// Элемент переместился влево
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
		}

		28.75% {
			@if ($count == 'odd') {
				transform: scale(60%, 1) translateX(60%) skewX(20deg);
			}
		}

		32.5% {
			transform: scale(60%, 1) translateX(-60%) skewX(20deg);
			@if ($count == 'odd') {
				transform: scale(60%, 1) translateX(60%) skewX(20deg);
			}
		}

		37.5% {
			transform: scale(1) translateX(0) skewX(-10deg);
			@if ($count == 'odd') {
				transform: scale(80%, 1) translateX(35%) skewX(-10deg);
			}
		}

		41.25% {
			transform: scale(1) translateX(0) skewX(-6deg);
			@if ($count == 'odd') {
				transform: scale(90%, 1) translateX(10%) skewX(-6deg);
			}
		}

		43.75% {
			transform: scale(1) translateX(0) skewX(4deg);
			@if ($count == 'odd') {
				transform: scale(1) translateX(0) skewX(4deg);
			}
		}

		45% {
			transform: scale(1) translateX(0) skewX(8deg);
			@if ($count == 'odd') {
				transform: scale(1) translateX(0) skewX(8deg);
			}
		}

		46.25%,
		50% {
			transform: scale(1) translateX(0) skewX(0deg);
		}

		// Анимация движения левого элемента
		51.25%,
		68.75% {
			transform: scale(1) translateX(0) skewX(0);
		}

		58.75% {
			transform: scale(calc(100% - 2%)) translateX(0) skewX(0);
		}

		60% {
			transform: scale(calc(100% - 2.68%)) translateX(0) skewX(0);
		}

		61.25% {
			transform: scale(calc(100% - 4%)) translateX(0) skewX(0);
		}

		62.5% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(0);
		}

		63.75% {
			transform: scale(calc(100% - 5%)) translateX(0) skewX(0);
		}

		65% {
			transform: scale(calc(100% - 4.5%)) translateX(0) skewX(0);
		}

		66.25% {
			transform: scale(calc(100% - 2%)) translateX(0) skewX(0);
		}

		67.5% {
			transform: scale(calc(100% + 4%)) translateX(0) skewX(0);
		}

		70% {
			transform: scale(calc(100% + 6%)) translateX(0) skewX(0);
		}

		71.25% {
			transform: scale(calc(100% + 2%)) translateX(0) skewX(2deg);
		}

		72.5% {
			transform: scale(calc(100% + 1%)) translateX(0) skewX(2deg);
		}

		73.75% {
			transform: scale(1) translateX(0) skewX(8deg);
		}

		75%, 80% {
			transform: scale(calc(100% - 0.5%)) translateX(0) skewX(12deg);
		}

		81.25% {
			transform: scale(1) translateX(0) skewX(8deg);
		}

		82.5% {
			transform: scale(1) translateX(0) skewX(6deg);
		}

		83.75% {
			transform: scale(1) translateX(0) skewX(0deg);
		}

		85% {
			transform: scale(1) translateX(0) skewX(-8deg);
		}

		86.25% {
			transform: scale(1) translateX(0) skewX(-12deg);
		}

		87.5% {
			transform: scale(1) translateX(0) skewX(-18deg);
		}

		90% {
			transform: scale(1) translateX(0) skewX(-24deg);
		}

		91.25% {
			transform: scale(1) translateX(0) skewX(-28deg);
		}

		92.5% {
			transform: scale(1) translateX(0) skewX(-24deg);
		}

		93.75% {
			transform: scale(1) translateX(0) skewX(-20deg);
		}

		95% {
			transform: scale(1) translateX(0) skewX(-12deg);
		}

		96.25%,
		100% {
			transform: scale(1) translateX(0) skewX(-20deg);
		}
	}
}

@keyframes loader-container {
	16.25% {
		transform: translateX(0);
	}

	43.75%,
	65% {
		transform: translateX(50%);
	}

	100% {
		transform: translateX(100%);
	}
}
