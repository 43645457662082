@use '~@tutu/mother-of-tokens/styles/radio.scss';
@use 'sass/helpers.scss' as utils;

.input {
	appearance: none;
	margin: unset;
	border-style: solid;
	background-color: var(--kite-radio-unchecked-default-background);
	border-color: var(--kite-radio-unchecked-default-border);

	&:not(:disabled) {
		cursor: pointer;
	}

	&:not(:disabled):hover {
		cursor: pointer;
		background-color: var(--kite-radio-unchecked-hover-background);
		border-color: var(--kite-radio-unchecked-hover-border);

		&:checked {
			background-color: var(--kite-radio-checked-hover-checked-border);
			border-color: var(--kite-radio-checked-hover-checked-border);
		}
	}

	&:checked {
		background-color: var(--kite-radio-checked-default-checked-border);
		border-color: var(--kite-radio-checked-default-checked-border);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='25' fill='white' /%3E%3C/svg%3E");
	}

	&:disabled {
		border-color: var(--kite-radio-unchecked-disabled-border);
		background-color: var(--kite-radio-unchecked-disabled-background);

		&:checked {
			border-color: var(--kite-radio-checked-disabled-border);
			background-color: var(--kite-radio-checked-disabled-checked-border);
			// Нужны разные иконки для dark и light темы
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='25' fill='rgb(179, 181, 204)' /%3E%3C/svg%3E");
		}
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $value in 400, 500 {
			.input.size#{$infix}-#{$value} {
				width: var(--kite-radio-#{$value}-size);
				height: var(--kite-radio-#{$value}-size);
				border-width: var(--kite-radio-#{$value}-border-width);
				border-radius: var(--kite-radio-#{$value}-border-radius);

				&.error {
					box-shadow: 0 0 0 var(--kite-radio-#{$value}-error-border-width) var(--kite-radio-error-border);
				}
			}
		}
	}
}
