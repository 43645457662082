@use 'sass:map';
@use '~@tutu/mother-of-tokens/styles/rich-icon.scss';
@use '~@tutu/mother-of-tokens/styles/public/semantic-colors.scss';
@use 'sass/helpers.scss' as utils;
@use '../../../helpers/semanticColors/style.scss' as semantic;

$size-list: (100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900);
$style-list: (primary, secondary, brand, positive, warning, negative);

.rich-icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.icon {
	position: relative;
	z-index: 1;
}

.label {
	position: absolute;
	text-align: center;
}

.image {
	position: absolute;
	z-index: 0;
}

.clickable {
	cursor: pointer;
}

@each $breakpoint in map.keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in $size-list {
			.size-#{$infix}-#{$size} {
				width: var(--kite-rich-icon-#{$size}-background-size);
				height: var(--kite-rich-icon-#{$size}-background-size);
				font-size: var(--kite-rich-icon-#{$size}-icon-size);

				&.shape-noShape {
					font-size: var(--kite-rich-icon-#{$size}-background-size);
				}

				.label {
					padding-bottom: var(--kite-rich-icon-#{$size}-label-compensation);
				}
			}
		}
	}
}

.shape- {
	&circle {
		border-radius: 50%;
	}

	&squircle {
		border-radius: 30%;
	}

	&circle,
	&squircle {
		@each $style in $style-list {
			&.style-#{$style} {
				background-color: var(--kite-rich-icon-background-#{$style});
				color: var(--kite-rich-icon-icon-#{$style});
			}
		}
		@each $style in semantic.$content-colors {
			&.style-#{$style} {
				background-color: var(--kite-overlay-#{map-get(semantic.$overlay-map, $style)});
				color: var(--kite-content-#{$style});
			}
		}
	}

	&noShape {
		@each $style in $style-list {
			&.style-#{$style} {
				color: var(--kite-rich-icon-icon-#{$style});
			}
		}
		@each $style in semantic.$content-colors {
			&.style-#{$style} {
				color: var(--kite-content-#{$style});
			}
		}
	}
}
