@use "sass:map";
@use '~@tutu/mother-of-tokens/styles/modal.scss';
@use '~@tutu/mother-of-tokens/styles/public/spacing.scss' as *;
@use 'sass/helpers.scss' as utils;

@each $breakpoint in map.keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		.dialog {
			width: var(--kite-modal-dialog-width-#{$breakpoint}, 100%);
		}

		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $value in 400, 500, 600 {
			.dialogButtons#{$infix}-#{$value} {
				margin-top: var(--kite-modal-overlay-container-#{$value}-content-padding-bottom);
				padding-bottom: calc(var(--kite-modal-overlay-container-#{$value}-content-padding-horizontal) - var(--kite-modal-overlay-container-#{$value}-content-padding-bottom));
			}
		}
	}
}

.dialogButtonSpace {
	margin-right: $spacing-300;
}
