@use 'sass:map';
@use 'sass:string';
@use 'sass:list';
@use '~@tutu/mother-of-tokens/styles/public/typography.scss' as typo;
@use 'sass/helpers.scss' as utils;

// Переопределяем рутовый размер шрифта здесь, т.к. относится конкретно к типографике
html,
[data-ds='kite'] {
	font-size: typo.$rootFontSize;
	font-family: typo.$rootFontFamily;
}

// Trims the start/left of the string:
@function str-trimStart($str) {
	@if (str-slice($str, 1, 1) == ' ') {
		@return str-trimStart(str-slice($str, 2));
	} @else {
		@return $str;
	}
}

// Trims the end/right of the string:
@function str-trimEnd($str) {
	@if (str-slice($str, str-length($str), -1) == ' ') {
		@return str-trimEnd(str-slice($str, 1, -2));
	} @else {
		@return $str;
	}
}

// Trims both the start and end of the string:
@function str-trim($str) {
	@return str-trimStart(str-trimEnd($str));
}

// Преобразует строку в валидный font-family
// "Tutu Sans, Segou UI, Helvetica, sans-serif" => "Tutu Sans", "Segou UI", Helvetica, sans-serif
@function escape-font-family($font-family) {
	$fonts: string.split($font-family, ',');
	$escaped: ();
	@each $font in $fonts {
		$font-name: str-trim($font);

		$tokens: string.split($font-name, ' ');
		@if list.length($tokens) == 1 {
			$escaped: list.append($escaped, string.unquote($font-name), comma);
		} @else {
			$escaped: list.append($escaped, string.quote($font-name), comma);
		}
	}
	@return $escaped;
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $variant, $sizes in typo.$typography {
			@each $size, $styles in $sizes {
				@each $style, $css in $styles {
					// @TODO: в mother-of-tokens должны быть валидные css свойства вместо строк.
					$font-family: escape-font-family(map.get($css, 'font-family'));
					$font-weight: map.get($css, 'font-weight');
					$font-size: string.unquote(map.get($css, 'font-size'));
					$lint-height: string.unquote(map.get($css, 'line-height'));
					$letter-spacing: map.get($css, 'letter-spacing');
					$text-transform: string.unquote(map.get($css, 'text-transform'));
					$font-feature-settings: normal;
					@if (map.has-key($css, 'font-feature-settings')) {
						$font-feature-settings: string.unquote(map.get($css, 'font-feature-settings'));
					}
					$text-decoration: string.unquote(map.get($css, 'text-decoration'));

					.typo#{$infix}-#{$variant}-#{$size}-#{$style} {
						font: $font-weight $font-size/$lint-height $font-family;
						// @TODO: Много повторяющихся свойств с одинаковыми значениями.
						// Можно вынести в отдельный класс
						letter-spacing: $letter-spacing;
						text-transform: $text-transform;
						font-feature-settings: $font-feature-settings;
						text-decoration: $text-decoration;
					}
				}
			}
		}
	}
}
