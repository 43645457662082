@use 'sass:map';
@use '~@tutu/mother-of-tokens/styles/main-layout';
@use 'sass/helpers.scss' as utils;

.mainLayoutContainer {
	box-sizing: border-box;
	margin: 0 auto;
	max-width: map.get(utils.$grid-breakpoints, 'xxl');
}

@each $breakpoint in map.keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		.mainLayoutContainer {
			padding-left: var(--kite-main-layout-margin-horizontal-#{$breakpoint});
			padding-right: var(--kite-main-layout-margin-horizontal-#{$breakpoint});

			&.ignoreMargin {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
