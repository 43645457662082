@use '~@tutu/mother-of-tokens/styles/progress-cell.scss';

.ProgressCell {
	position: relative;
	padding-top: var(--kite-progress-cell-padding-vertical);
	padding-bottom: var(--kite-progress-cell-padding-vertical);
}

.ProgressCellBar-bottom {
	margin-top: var(--kite-progress-cell-gap);
}

.ProgressCellBar-top {
	margin-bottom: var(--kite-progress-cell-gap);
}
