@use 'sass/helpers.scss' as utils;

$align-list: start, center, end;

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $align in $align-list {
			.text#{$infix}-#{$align} {
				text-align: $align;
			}
		}
	}
}
