@use '~@tutu/mother-of-tokens/styles/chip-row';
@use 'sass/helpers.scss' as utils;

$prefix: '--kite-chip-row-chip-';

.chipRowItem {
	cursor: pointer;
	user-select: none;

	.text {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding-bottom: var(#{$prefix}common-list-padding-bottom);
	}
}

.chipRowItem.primary {
	background: var(#{$prefix}background-primary-unselected-default);

	.leftSlot .icon {
		color: var(#{$prefix}addon-left-primary-unselected) !important;
	}

	.rightSlot .icon {
		color: var(#{$prefix}addon-right-primary-unselected) !important;
	}

	.label {
		color: var(#{$prefix}label-text-primary-unselected);
	}

	.subtitle {
		color: var(#{$prefix}subtitle-text-primary-unselected);
	}

	.value {
		color: var(#{$prefix}value-text-primary-unselected);
	}

	.subValue {
		color: var(#{$prefix}subvalue-text-primary-unselected);
	}

	&.selected {
		color: var(#{$prefix}label-text-primary-selected);
		background: var(#{$prefix}background-primary-selected-default);

		.leftSlot .icon {
			color: var(#{$prefix}addon-left-primary-selected) !important;
		}

		.rightSlot .icon {
			color: var(#{$prefix}addon-right-primary-selected) !important;
		}

		.label {
			color: var(#{$prefix}label-text-primary-selected);
		}

		.subtitle {
			color: var(#{$prefix}subtitle-text-primary-selected);
		}

		.value {
			color: var(#{$prefix}value-text-primary-selected);
		}

		.subValue {
			color: var(#{$prefix}subvalue-text-primary-selected);
		}
	}

	&:hover:not(.selected) {
		background: var(#{$prefix}background-primary-unselected-hover);
	}
}

.chipRowItem.secondary {
	background: var(#{$prefix}background-secondary-unselected-default);

	.leftSlot .icon {
		color: var(#{$prefix}addon-left-secondary-unselected) !important;
	}

	.rightSlot .icon {
		color: var(#{$prefix}addon-right-secondary-unselected) !important;
	}

	.label {
		color: var(#{$prefix}label-text-secondary-unselected);
	}

	.subtitle {
		color: var(#{$prefix}subtitle-text-secondary-unselected);
	}

	.value {
		color: var(#{$prefix}value-text-secondary-unselected);
	}

	.subValue {
		color: var(#{$prefix}subvalue-text-secondary-unselected);
	}

	&.selected {
		color: var(#{$prefix}label-text-secondary-selected);
		background: var(#{$prefix}background-secondary-selected-default);
		box-shadow: inset 0 0 0 var(#{$prefix}common-border-width) var(#{$prefix}border-secondary-selected-default);

		.leftSlot .icon {
			color: var(#{$prefix}addon-left-secondary-selected) !important;
		}

		.rightSlot .icon {
			color: var(#{$prefix}addon-right-secondary-selected) !important;
		}

		.label {
			color: var(#{$prefix}label-text-secondary-selected);
		}

		.subtitle {
			color: var(#{$prefix}subtitle-text-secondary-selected);
		}

		.value {
			color: var(#{$prefix}value-text-secondary-selected);
		}

		.subValue {
			color: var(#{$prefix}subvalue-text-secondary-selected);
		}
	}

	&:hover.selected {
		box-shadow: inset 0 0 0 var(#{$prefix}common-border-width) var(#{$prefix}border-secondary-selected-hover);
	}

	&:hover:not(.selected) {
		background: var(#{$prefix}background-secondary-unselected-hover);
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in 300, 400, 500 {
			.size#{$infix}-#{$size} {
				box-sizing: border-box;
				display: inline-flex;
				flex: 0 0 auto;
				justify-content: center;
				align-items: center;

				height: var(#{$prefix}#{$size}-height);
				min-width: var(#{$prefix}#{$size}-min-width);
				border-radius: var(#{$prefix}#{$size}-border-radius);
				padding-left: var(#{$prefix}#{$size}-padding-horizontal);
				padding-right: var(#{$prefix}#{$size}-padding-horizontal);

				.leftSlot {
					padding-left: var(#{$prefix}#{$size}-left-slot-padding-left);
					height: 100%;
				}

				.rightSlot {
					padding-right: var(#{$prefix}#{$size}-right-slot-padding-right);
					height: 100%;
				}

				.leftSlot:only-child {
					padding-left: 0;
				}

				.rightSlot:only-child {
					padding-right: 0;
				}

				.addon {
					display: flex;
					align-items: center;
					padding-left: var(#{$prefix}common-addon-padding-horizontal);
					padding-right: var(#{$prefix}common-addon-padding-horizontal);
					height: 100%;
				}

				.text {
					padding-left: var(#{$prefix}common-list-mid-padding-horizontal);
					padding-right: var(#{$prefix}common-list-mid-padding-horizontal);
				}

				.text:only-child {
					align-items: center;
				}

				.leftSlot + .text {
					padding-left: 0;
				}

				.textHasRightSlot {
					padding-right: 0;
				}
			}
		}
	}
}
