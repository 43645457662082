@use '~@tutu/mother-of-tokens/styles/label-value-group.scss';

@use 'sass/helpers.scss' as utils;

$variants: ('x-large', 'large', 'medium', 'small', 'x-small', 'xx-small');

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $variant in $variants {
			.header-#{$variant}#{$infix} {
				padding-top: var(--kite-label-value-group-header-#{$variant}-padding-top);
				padding-bottom: var(--kite-label-value-group-header-#{$variant}-padding-bottom);
				gap: var(--kite-label-value-group-header-#{$variant}-gap);
			}

			.header-icon-#{$variant}#{$infix} {
				padding-top: var(--kite-label-value-group-header-#{$variant}-icon-padding-top);
			}
		}
	}
}

.group {
	&-header-wrapper {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&-items-wrapper {
		display: flex;
		flex-direction: column;
		row-gap: var(--kite-label-value-group-content-gap);
		padding-top: var(--kite-label-value-group-content-padding-top);
		padding-bottom: var(--kite-label-value-group-content-padding-bottom);
	}

	&-items-row {
		display: flex;
		align-items: baseline;
		column-gap: var(--kite-label-value-group-table-item-gap);
	}
}
