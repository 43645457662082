@use 'sass/helpers.scss' as utils;

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $value in $displays {
			.d#{$infix}-#{$value} {
				display: $value !important;
			}
		}
	}
}
