@use '~@tutu/mother-of-tokens/styles/pagination-bar.scss';
@use 'sass/helpers.scss' as utils;

$duration: 300ms;
$interpolation: cubic-bezier(0.45, 0, 0.55, 1);

.content {
	position: absolute;
	display: inline-flex;
	top: 0;
	align-items: center;
	transition: margin-left, margin-right;
	transition-duration: $duration, $duration;
	transition-timing-function: $interpolation, $interpolation;
}

.dot,
.middle {
	flex-grow: 0;
	display: block;
	transition: width, padding-bottom, margin-right;
	transition-duration: $duration, $duration, $duration;
	transition-timing-function: $interpolation, $interpolation, $interpolation;

	&:before {
		background: var(--kite-pagination-bar-default-background);
	}

	&:after {
		background: var(--kite-pagination-bar-default-bar) !important;
	}
}

.static {
	&:before {
		background: var(--kite-pagination-bar-static-background);
	}

	&:after {
		background: var(--kite-pagination-bar-static-bar) !important;
	}
}

.middle {
	width: var(--kite-pagination-bar-layout-middle-width);
	flex-shrink: 0;
	transition: width, padding-bottom;
	transition-duration: $duration, $duration;
	transition-timing-function: $interpolation, $interpolation;
}

.auto {
	&:after {
		animation: pagination-bar-slide var(--pagination-bar-slide-transition-duration) linear;
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in 100, 200 {
			$margin-0: var(--kite-pagination-bar-layout-gap);
			$margin-1: calc(
				0px - var(--kite-pagination-bar-layout-gap)
			);
			$margin-2: calc(
				0px - var(--kite-pagination-bar-layout-#{$size}-item-300-width) * 2 - var(--kite-pagination-bar-layout-gap)
			);
			$margin-3: calc(
				0px - var(--kite-pagination-bar-layout-#{$size}-item-300-width) * 2 - var(--kite-pagination-bar-layout-#{$size}-item-200-width) - var(--kite-pagination-bar-layout-gap) * 2);
			$margin-max: calc(
				0px - var(--kite-pagination-bar-layout-#{$size}-item-300-width) * 2 - var(--kite-pagination-bar-layout-#{$size}-item-200-width) - var(--kite-pagination-bar-layout-#{$size}-item-100-width) - var(--kite-pagination-bar-layout-gap) * 3
			);
			$totalSize: calc(var(--kite-pagination-bar-layout-#{$size}-side-width) * 2 + var(--kite-pagination-bar-layout-middle-width) + var(--kite-pagination-bar-layout-gap) * 2);
			.paginationBar {
				display: inline-block;
				height: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
				box-sizing: border-box;
				position: relative;
				width: $totalSize;
				overflow: hidden;
				text-align: left;

				&#{$infix}-#{$size} {
					padding: 0 var(--kite-pagination-bar-layout-#{$size}-side-width);

					.prev {
						&-#{0} {
							margin-left: $margin-0;
						}

						&-#{1} {
							margin-left: $margin-1;
						}

						&-#{2} {
							margin-left: $margin-2;
						}

						&-#{3} {
							margin-left: $margin-3;
						}

						&Max {
							margin-left: $margin-max;
						}
					}

					.last {
						&-#{0} {
							margin-right: $margin-0;
						}

						&-#{1} {
							margin-right: $margin-1;
						}

						&-#{2} {
							margin-right: $margin-2;
						}

						&-#{3} {
							margin-right: $margin-3;
						}

						&Max {
							margin-right: $margin-max;
						}
					}

					.middle {
						padding-bottom: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
						border-radius: var(--kite-pagination-bar-layout-#{$size}-item-300-height);

						&:before,
						&:after {
							height: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
							border-radius: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
						}
					}

					.dot {
						width: 0;
						padding-bottom: 0;
						margin-right: 0;

						&:before, &:after {
							height: 0;
						}
					}

					.dot-1, .dot-2, .dot-3, .dot-4, .middle {
						margin-right: var(--kite-pagination-bar-layout-gap);
					}

					.dot-1, .dot-2 {
						width: var(--kite-pagination-bar-layout-#{$size}-item-300-width);
						padding-bottom: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
						border-radius: var(--kite-pagination-bar-layout-#{$size}-item-300-height);

						&:before, &:after {
							height: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
							border-radius: var(--kite-pagination-bar-layout-#{$size}-item-300-height);
						}
					}

					.dot-3 {
						width: var(--kite-pagination-bar-layout-#{$size}-item-200-width);
						padding-bottom: var(--kite-pagination-bar-layout-#{$size}-item-200-height);
						border-radius: var(--kite-pagination-bar-layout-#{$size}-item-200-height);

						&:before, &:after {
							height: var(--kite-pagination-bar-layout-#{$size}-item-200-height);
							border-radius: var(--kite-pagination-bar-layout-#{$size}-item-200-height);
						}
					}

					.dot-4 {
						width: var(--kite-pagination-bar-layout-#{$size}-item-100-width);
						padding-bottom: var(--kite-pagination-bar-layout-#{$size}-item-100-height);
						border-radius: var(--kite-pagination-bar-layout-#{$size}-item-100-height);

						&:before, &:after {
							height: var(--kite-pagination-bar-layout-#{$size}-item-100-height);
							border-radius: var(--kite-pagination-bar-layout-#{$size}-item-100-height);
						}
					}
				}
			}
		}
	}
}

@keyframes pagination-bar-slide {
	to {
		transform: translateX(0%);
	}
}
