@use '~@tutu/mother-of-tokens/styles/cell.scss';
@use '~@tutu/mother-of-tokens/styles/separator.scss';
@use '~@tutu/mother-of-tokens/styles/label-value-list.scss';
@use '~@tutu/mother-of-tokens/styles/text.scss';
@use 'sass/helpers.scss' as utils;

button {
	&.cell {
		width: 100%;
		border: initial;
		background-color: initial;
		cursor: pointer;
	}
}

.cell {
	display: flex;
	justify-items: stretch;
	align-items: start;

	&.alternative {
		align-items: center;
	}

	:global(.kite-label-value-list) {
		flex: 1 1 100%;
		align-self: center;
		margin: var(--kite-cell-list-inner-padding-vertical) 0;
	}

	> .group {
		flex: 1 1 100%;
		align-self: stretch;
	}

	& > :global(.kite-label-value) {
		flex: 1 1 100%;
		align-self: center;
	}

	& > :global(.kite-label-value):not(:global(.kite-label-value-emphasized)) {
		color: var(--kite-text-secondary);
	}

	&.labelOnly {
		display: block;
	}

	& > .addon {
		display: flex;
		align-items: center;
		flex: 0 0 auto;
	}

	&.alternative > .addon:first-child {
		align-items: center;
		align-self: flex-start;
	}

	& > .addon:last-child {
		align-items: center;
	}
}

.hasSeparator {
	box-shadow: inset 0 calc(-1 * var(--kite-separator-width)) var(--kite-separator-primary-background);
}

$list-size-contrast: (small, plain), (small, small), (small, medium), (small, large), (medium, plain), (medium, small), (medium, medium), (medium, large),
	(large, plain), (large, small), (large, medium), (large, large);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $size in 150, 200, 250, 350, 400, 450, 500, 550, 600, 700, 750, 800, 900 {
			.addon#{$infix}-#{$size},
			.addon#{$infix}-#{$size} > .group {
				gap: var(--kite-cell-addon-#{$size}-spacing);

				& > .addon {
					gap: var(--kite-cell-addon-#{$size}-spacing);
					min-height: var(--kite-cell-addon-#{$size}-min-height);
				}
			}
		}

		@each $size in 100, 250, 300, 350, 400, 450, 500 {
			.list#{$infix}-#{$size},
			.list#{$infix}-#{$size} > .group {
				--kite-cell-list-inner-padding-vertical: var(--kite-cell-list-#{$size}-padding-vertical);

				& > :global(.kite-label-value) {
					margin: calc(
						var(--kite-cell-list-#{$size}-padding-vertical) +
						var(--kite-cell-from-list-padding-vertical)
					) 0;
				}
			}
		}

		@each $size in 100, 200, 300, 500, 600 {
			.layout#{$infix}-#{$size},
			.layout#{$infix}-#{$size} > .group {
				padding: var(--kite-cell-layout-#{$size}-padding-vertical) 0;

				&.labelOnly {
					padding-top: calc(
						var(--kite-cell-layout-#{$size}-padding-vertical) +
						var(--kite-cell-list-inner-padding-vertical) +
						var(--kite-cell-from-list-padding-vertical) +
						var(--kite-label-value-list-from-label-padding-top)
					);
					padding-bottom: calc(
						var(--kite-cell-layout-#{$size}-padding-vertical) +
						var(--kite-cell-list-inner-padding-vertical) +
						var(--kite-cell-from-list-padding-vertical) +
						var(--kite-label-value-list-from-label-padding-bottom)
					);
				}
			}

			.layout#{$infix}-#{$size} > .group {
				margin: calc(-1 * var(--kite-cell-layout-#{$size}-padding-vertical)) 0;
			}
		}

		@each $size, $contrast in $list-size-contrast {
			.listSizeContrast#{$infix}-#{$size}-#{$contrast},
			.listSizeContrast#{$infix}-#{$size}-#{$contrast} > .group {
				--kite-cell-from-list-padding-vertical: var(
						--kite-label-value-list-default-padding-vertical-size-#{$size}-contrast-#{$contrast}
				);
			}
		}
	}
}
