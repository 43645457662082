@use '~@tutu/mother-of-tokens/styles/counter.scss';
@use 'sass/helpers.scss' as utils;
@use 'sass:map';

.counter {
	display: flex;
	align-items: center;

	& .text {
		width: 24px;
		text-align: center;
		overflow: hidden;
	}

	&.fullWidth {
		width: 100%;
		justify-content: space-between;
	}
}

$sizes: 400, 500;

@each $breakpoint in map.keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in $sizes {
			.size#{$infix}-#{$size} {
				column-gap: var(--kite-counter-#{$size}-gap);
			}
		}
	}
}
