@use '~@tutu/mother-of-tokens/styles/modal.scss';
@use 'sass/helpers.scss' as utils;

.modalHeader {
	display: flex;
}

.overlayPosition {
	position: absolute;
	top: 0;
	left: 0;
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $value in 400, 500, 600 {
			.size#{$infix}-#{$value} {
				padding: var(--kite-modal-header-#{$value}-padding-vertical) var(--kite-modal-header-#{$value}-padding-horizontal);
				gap: var(--kite-modal-header-#{$value}-gap);

				&.overlayPosition {
					width: calc(100% - var(--kite-modal-header-#{$value}-padding-horizontal) * 2);
				}

				.title {
					padding: var(--kite-modal-header-#{$value}-title-container-padding-vertical) var(--kite-modal-header-#{$value}-title-container-padding-horizontal);
					min-height: var(--kite-modal-header-#{$value}-title-container-min-height);
				}
			}
		}
	}
}

.title {
	flex: 1 1 auto;
	align-self: center;
	box-sizing: border-box;
}

.backButton,
.closeButton {
	flex: 0 0 auto;
}
