@use '~@tutu/mother-of-tokens/styles/checkbox.scss';
@use 'sass/helpers.scss' as utils;

.input-wrapper {
	position: relative;
	display: inline-block;
}

.input {
	appearance: none;
	display: inline-block;
	margin: unset;

	background-color: var(--kite-checkbox-unchecked-default-background);
	border-color: var(--kite-checkbox-unchecked-default-border);
	border-style: solid;
	box-sizing: border-box;

	&:not(:disabled) {
		cursor: pointer;
	}

	&:checked {
		background-color: var(--kite-checkbox-checked-default-background);
		border-color: var(--kite-checkbox-checked-default-background);

		& ~ .icon-wrapper {
			display: block;
			fill: var(--kite-checkbox-checked-default-icon);
		}
	}

	&:disabled {
		background-color: var(--kite-checkbox-unchecked-disabled-background);
		border-color: var(--kite-checkbox-unchecked-disabled-border);

		&:checked {
			& ~ .icon-wrapper {
				fill: var(--kite-checkbox-checked-disabled-icon);
			}

			background-color: var(--kite-checkbox-checked-disabled-background);
			border-color: var(--kite-checkbox-checked-disabled-border);
		}
	}

	&:not(:disabled):hover {
		background-color: var(--kite-checkbox-unchecked-hover-background);
		border-color: var(--kite-checkbox-unchecked-hover-border);

		& ~ .icon-wrapper {
			fill: var(--kite-checkbox-checked-hover-icon);
		}

		&:checked {
			background-color: var(--kite-checkbox-checked-hover-background);
		}
	}
}

.icon-wrapper {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	overflow: hidden;

	svg {
		width: 100%;
		height: 100%;
	}
}

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $value in 400, 500 {
			.size#{$infix}-#{$value} {
				&.input {
					width: var(--kite-checkbox-#{$value}-size);
					height: var(--kite-checkbox-#{$value}-size);
					border-width: var(--kite-checkbox-#{$value}-border-width);
					border-radius: var(--kite-checkbox-#{$value}-border-radius);
				}

				&.input-wrapper {
					width: var(--kite-checkbox-#{$value}-size);
					height: var(--kite-checkbox-#{$value}-size);
					border-radius: var(--kite-checkbox-#{$value}-border-radius);
				}

				&.input-wrapper.error {
					box-shadow: 0
						0
						0
						var(--kite-checkbox-#{$value}-error-border-width)
						var(--kite-checkbox-error-border);
				}

				&.icon-wrapper {
					width: var(--kite-checkbox-#{$value}-size);
					height: var(--kite-checkbox-#{$value}-size);
					border-width: var(--kite-checkbox-#{$value}-border-width);
					border-radius: var(--kite-checkbox-#{$value}-border-radius);
				}
			}
		}
	}
}
