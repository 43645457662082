@import '~@tutu/mother-of-tokens/styles/separator.scss';

.Separator {
	&.horizontal {
		width: 100%;
		height: var(--kite-separator-width);
	}

	&.vertical {
		width: var(--kite-separator-width);
		height: 100%;
	}

	&.primary {
		background: var(--kite-separator-primary-background);
	}
}
