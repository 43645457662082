@use '~@tutu/mother-of-tokens/styles/label-value.scss';
@use '~@tutu/mother-of-tokens/styles/text.scss';
@use 'sass/helpers.scss' as utils;

$variant-list: (
	'header-x-large',
	'header-large',
	'header-medium',
	'header-small',
	'paragraph-large-compact',
	'paragraph-large',
	'paragraph-medium-compact',
	'paragraph-medium',
	'paragraph-small'
);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $variant in $variant-list {
			.default.default-variant#{$infix}-#{$variant} {
				padding-top: var(--kite-label-value-#{$variant}-default-padding-top);
				padding-bottom: var(--kite-label-value-#{$variant}-default-padding-bottom);
				min-height: var(--kite-label-value-#{$variant}-default-min-height);
				gap: var(--kite-label-value-#{$variant}-default-gap);
			}
		}
	}
}

:global(.kite-label-value-emphasized) {
	color: var(--kite-text-primary);
}

.default.labelOnly,
.label,
.value {
	&.accent {
		font-weight: 600;
	}
}

.default {
	box-sizing: border-box;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	width: 100%;

	&.expanded-false {
		flex-grow: 0;
		width: auto;
		display: inline-flex;

		.value {
			max-width: initial;
		}
	}

	&.valueOnly {
		gap: 0;
		justify-content: end;
		overflow-x: hidden;

		&::before {
			content: '&';
			visibility: hidden;
			width: 0;
		}

		.value {
			max-width: 100%;
			width: 100%;
		}
	}

	&.labelOnly {
		display: block;

		&.expanded-false {
			display: inline-block;
		}
	}

	&.truncate {
		@include utils.truncate()
	}

	&.withMaxLines {
		.value,
		.label {
			@include utils.truncate()
		}
	}
}

.label {
	box-sizing: border-box;
	flex: 1 1 auto;
	display: block;

	&.expanded-false {
		flex-grow: 0;
		width: auto;
		display: inline-block;
	}
}

.value {
	flex: 0 0 auto;
	word-wrap: break-word;

	&.numeric {
		font-feature-settings: 'tnum';
	}
}

.crossed {
	text-decoration: line-through;
}
