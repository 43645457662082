@use 'sass:map';
@use '~@tutu/mother-of-tokens/styles/main-layout';
@use 'sass/helpers.scss' as utils;

$grid-columns: 12;
$column-gap: var(--kite-main-layout-gutter-md);

.mainLayoutRow {
	display: grid;
	grid-template-columns: repeat($grid-columns, 1fr);
	column-gap: $column-gap;
}

@each $breakpoint in map.keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		.mainLayoutRow {
			margin-left: 0;
			margin-right: 0;
			column-gap: var(--kite-main-layout-gutter-#{$breakpoint});
			row-gap: var(--kite-main-layout-gutter-#{$breakpoint});

			&.ignoreMargin {
				padding-left: var(--kite-main-layout-margin-horizontal-#{$breakpoint});
				margin-left: calc(-1 * var(--kite-main-layout-margin-horizontal-#{$breakpoint}));
				padding-right: var(--kite-main-layout-margin-horizontal-#{$breakpoint});
				margin-right: calc(-1 * var(--kite-main-layout-margin-horizontal-#{$breakpoint}));
			}
		}
	}
}
