@use 'sass/helpers.scss' as utils;
@use '../../helpers/semanticColors/style.scss' as semantic;
@use '~@tutu/mother-of-tokens/styles/panel.scss';
@use '~@tutu/mother-of-tokens/styles/public/semantic-colors.scss';

$sizes: 300, 400, 500, 600, 700;
$corners: top-left, top-right, bottom-left, bottom-right;

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $value in $sizes {
			@each $corner in $corners {
				.#{$corner}#{$infix}-#{$value} {
					--kite-panel-border-#{$corner}-radius: var(--kite-panel-#{$value}-border-radius);
				}
			}
		}
		@each $corner in $corners {
			.#{$corner}-rounded#{$infix}-true {
				border-#{$corner}-radius: var(--kite-panel-border-#{$corner}-radius);
			}
			.#{$corner}-rounded#{$infix}-false {
				border-#{$corner}-radius: 0;
			}
		}
	}
}

$colors: primary, secondary, tertiary, brand, positive, warning, negative;

@each $value in $colors {
	.#{$value} {
		background-color: var(--kite-panel-#{$value});
	}
}

@each $value in semantic.$background-colors {
	.#{$value} {
		background-color: var(--kite-background-#{$value});
	}
}

.transparentBg {
	background-color: transparent;
}

@each $value in semantic.$border-colors {
	.border-#{$value} {
		box-shadow: inset 0 0 0 var(--kite-panel-border-unselected) var(--kite-border-#{$value});
	}
}

.panel {
	overflow: hidden;
}
