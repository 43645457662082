@use '~@tutu/mother-of-tokens/styles/slider-cell.scss';

.sliderCell {
	display: flex;
	padding: var(--kite-slider-cell-padding-vertical) 0;
	gap: var(--kite-slider-cell-gap);

	&.bottom {
		flex-direction: column;
	}

	&.top {
		flex-direction: column-reverse;
	}
}
