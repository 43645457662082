@use 'sass/helpers.scss' as utils;
@import '~@tutu/mother-of-tokens/styles/pimple.scss';

$sizes: 100, 400, 500;

.pimple {
	background: var(--kite-pimple-background);
	display: inline-flex;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;

	@each $breakpoint in map-keys(utils.$grid-breakpoints) {
		@include utils.media-breakpoint-up($breakpoint) {
			$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
			@each $size in $sizes {
				&#{$infix}-#{$size} {
					height: var(--kite-pimple-#{$size}-size);
					min-width: var(--kite-pimple-#{$size}-size);
					padding-left: var(--kite-pimple-#{$size}-padding-horizontal);
					padding-right: var(--kite-pimple-#{$size}-padding-horizontal);
					padding-bottom: var(--kite-pimple-#{$size}-padding-bottom);
					border-radius: var(--kite-pimple-#{$size}-border-radius);

					@if ($size==100) {
						color: transparent;
						width: var(--kite-pimple-#{$size}-size);
						user-select: none;
					} @else {
						color: var(--kite-pimple-label);
						width: auto;
						user-select: auto;
					}
				}
			}
		}
	}
}
