@use '~@tutu/mother-of-tokens/styles/semantic-text.scss';
@use 'sass/helpers.scss' as utils;

$sizes: paragraph-small, paragraph-medium, paragraph-large, header-xx-small, header-x-small, header-small, header-medium,
	header-large, header-x-large;

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in $sizes {
			.padding#{$infix}-#{$size} {
				padding-top: var(--kite-semantic-text-#{$size}-padding-top);
				padding-bottom: var(--kite-semantic-text-#{$size}-padding-bottom);
			}
			.padding#{$infix}-#{$size}-extra {
				padding-top: calc(
					var(--kite-semantic-text-#{$size}-padding-top) +
						var(--kite-semantic-text-#{$size}-padding-top-extra)
				);
				padding-bottom: var(--kite-semantic-text-#{$size}-padding-bottom);
			}
		}
	}
}

.semantic-text {
	margin: 0;
}

.listStyleTypeNone {
	list-style-type: none;
}
