@use 'sass/helpers.scss' as utils;
@use '../semanticColors/style.scss' as semantic;
@use '~@tutu/mother-of-tokens/styles/text.scss';
@use '~@tutu/mother-of-tokens/styles/public/semantic-colors.scss';

$variants: primary, secondary, brand, positive, warning, negative;

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $value in $variants {
			.text#{$infix}-#{$value} {
				color: var(--kite-text-#{$value});
			}
		}

		@each $value in semantic.$content-colors {
			.text#{$infix}-#{$value} {
				color: var(--kite-content-#{$value});
			}
		}
	}
}
