@use 'sass/helpers.scss' as utils;
@use '../../../helpers/semanticColors/style.scss' as semantic;
@use '~@tutu/mother-of-tokens/styles/public/semantic-colors.scss';

@import '~@tutu/mother-of-tokens/styles/progress-bar.scss';

:root {
	// Чтобы скрипт проверки переменных не ругался
	--kite-progress-bar-component-completion: initial;
}

.ProgressBar {
	all: unset;
	position: relative;
	overflow: hidden;
	display: flex;
	width: 100%;
	height: 0;

	&.background-default {
		//Firefox fallback
		@supports (-moz-appearance:none) {
			background: var(--kite-progress-bar-background);
		}

		&::-webkit-progress-bar,
		&::-webkit-progress-value, {
			background: var(--kite-progress-bar-background);
		}

		&:not(::-webkit-progress-bar:before),
		&:not(::-webkit-progress-bar:after) {
			background: var(--kite-progress-bar-background);
		}
	}

	@each $color in semantic.$background-colors {
		&.background-semantic-#{$color} {
			// Firefox
			@supports (-moz-appearance:none) {
				background: var(--kite-overlay-#{$color});
			}


			&:not(::-webkit-progress-bar:before),
			&:not(::-webkit-progress-bar:after) {
				background: var(--kite-overlay-#{$color});
			}


			&::-webkit-progress-bar,
			&::-webkit-progress-value {
				background: var(--kite-overlay-#{$color});
			}
		}
	}


	//Safari fallback
	&::-webkit-progress-inner-element {
		appearance: auto;
		padding-bottom: 0;
	}

	&::-webkit-progress-bar,
	&::-webkit-progress-value {
		transition: all 0.1s linear;
	}

	&::-moz-progress-bar {
		appearance: auto;
		padding-bottom: 0;
		transition: all 0.1s linear;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}

	&:after {
		transform: translateX(var(--kite-progress-bar-component-completion));
		will-change: transform;
		transition: transform 0.1s linear;
	}

	@each $color in positive, positive-low, warning, negative, negative-low {
		&.content-#{$color} {
			&:after {
				background: var(--kite-progress-bar-bar-#{$color});
			}

			&::-webkit-progress-value {
				background: var(--kite-progress-bar-bar-#{$color});
			}

			&::-moz-progress-bar {
				background: var(--kite-progress-bar-bar-#{$color});
			}
		}
	}

	@each $color in semantic.$content-colors {
		&.content-semantic-#{$color} {
			&:after {
				background: var(--kite-content-#{$color});
			}

			&::-webkit-progress-value {
				background: var(--kite-content-#{$color});
			}

			&::-moz-progress-bar {
				background: var(--kite-content-#{$color});
			}
		}
	}
}

$sizes: 100, 200;

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

		@each $size in $sizes {
			$height: var(--kite-progress-bar-background-height-#{$size});
			$borderRadius: calc($height / 2);

			.layout#{$infix}-#{$size} {
				border-radius: $borderRadius;
				padding-bottom: $height;

				&::-webkit-progress-bar,
				&::-webkit-progress-value {
					border-radius: $borderRadius;
				}

				&::-webkit-progress-inner-element {
					height: $height;
				}

				&::-moz-progress-bar {
					border-radius: $borderRadius;
					height: $height;
				}

				&:before,
				&:after {
					height: $height;
					border-radius: $borderRadius;
				}
			}
		}
	}
}
