@use 'sass/helpers.scss' as utils;
@use '~@tutu/mother-of-tokens/styles/public/typography.scss';
@use 'src/components/base/Input/input.scss' as input;

.DateField {
	position: relative;
	--kite-date-field-placeholder-display: none;

	&:focus-within {
		--kite-date-field-placeholder-display: flex;
	}

	.fieldInput {
		font-feature-settings: "tnum";
	}

	.placeholder {
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		display: var(--kite-date-field-placeholder-display, none);
		justify-content: flex-start;
		align-items: center;
		color: var(--kite-input-content-enabled-placeholder);
		pointer-events: none;
		font-feature-settings: "tnum";

		@each $breakpoint in map-keys(utils.$grid-breakpoints) {
			@include utils.media-breakpoint-up($breakpoint) {
				$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);

				@each $size in input.$size-list {
					&.size#{$infix}-#{$size} {
						@include input.input-placeholder-font-style($size);

						border-width: var(--kite-input-layout-#{$size}-border-width-focused);
						padding-top: calc(
							(var(--kite-input-layout-#{$size}-min-size) / 2) - var(--kite-input-content-#{$size}-padding-horizontal)
						);
						padding-left: calc(var(--kite-input-content-#{$size}-padding-horizontal) - (var(--kite-input-layout-#{$size}-border-width-focused) - var(--kite-input-layout-#{$size}-border-width-focused) - var(--kite-input-layout-#{$size}-border-width-default)) + var(--kite-input-content-#{$size}-input-padding-horizontal));
						padding-right: calc(var(--kite-input-content-#{$size}-padding-horizontal) - (var(--kite-input-layout-#{$size}-border-width-focused) - var(--kite-input-layout-#{$size}-border-width-focused) - var(--kite-input-layout-#{$size}-border-width-default)) + var(--kite-input-content-#{$size}-input-padding-horizontal));

					}
				}
			}
		}
	}
}
