@use '~@tutu/mother-of-tokens/styles/chip-row.scss';
@use 'sass/helpers.scss' as utils;

.chipRow {
	display: flex;
	flex-wrap: wrap;
	column-gap: var(--kite-chip-row-layout-gap-horizontal);
	row-gap: var(--kite-chip-row-layout-gap-vertical);

	&.scroll {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
	}
}

@include utils.media-breakpoint-between('xs', 'md') {
	.scrollBarMobileInvisible {
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}
