@use 'sass/helpers.scss' as utils;

@use '~@tutu/mother-of-tokens/styles/public/spacing.scss' as *;

$sizes: (
	0: $spacing-0,
	50: $spacing-50,
	100: $spacing-100,
	150: $spacing-150,
	200: $spacing-200,
	250: $spacing-250,
	300: $spacing-300,
	350: $spacing-350,
	400: $spacing-400,
	450: $spacing-450,
	500: $spacing-500,
	550: $spacing-550,
	600: $spacing-600,
	650: $spacing-650,
	700: $spacing-700,
	750: $spacing-750,
	800: $spacing-800,
	850: $spacing-850,
	900: $spacing-900,
);

@each $breakpoint in map-keys(utils.$grid-breakpoints) {
	@include utils.media-breakpoint-up($breakpoint) {
		$infix: utils.breakpoint-infix($breakpoint, utils.$grid-breakpoints);
		@each $size in map-keys($sizes) {
			.margin#{$infix}-#{$size} {
				margin: map-get($sizes, $size);
			}
			.padding#{$infix}-#{$size} {
				padding: map-get($sizes, $size);
			}
			.marginTop#{$infix}-#{$size} {
				margin-top: map-get($sizes, $size);
			}
			.marginBottom#{$infix}-#{$size} {
				margin-bottom: map-get($sizes, $size);
			}
			.marginRight#{$infix}-#{$size} {
				margin-right: map-get($sizes, $size);
			}
			.marginLeft#{$infix}-#{$size} {
				margin-left: map-get($sizes, $size);
			}
			.paddingTop#{$infix}-#{$size} {
				padding-top: map-get($sizes, $size);
			}
			.paddingBottom#{$infix}-#{$size} {
				padding-bottom: map-get($sizes, $size);
			}
			.paddingRight#{$infix}-#{$size} {
				padding-right: map-get($sizes, $size);
			}
			.paddingLeft#{$infix}-#{$size} {
				padding-left: map-get($sizes, $size);
			}
			.gap#{$infix}-#{$size} {
				gap: map-get($sizes, $size);
			}
		}
	}
}
